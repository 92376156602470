import EmployeesPage from '@/modules/employee/views/employees-page/EmployeesPage.vue'

export default [
  {
    path: '/employees/',
    name: 'employees',
    component: EmployeesPage,
    meta: {
      pageTitle: 'Employee',
      breadcrumb: [
        {
          text: 'Employees',
          to: '/employees',
          active: true,
        },
      ],
    },
  },
]
