import axios from 'axios'

export default {
  getProfile() {
    return axios.get('profile')
  },
  updateProfile(data) {
    return axios({ url: 'profile', data, method: 'PATCH' })
  },
  changeEmail(data) {
    return axios({ url: 'profile/change-email', data, method: 'POST' })
      .then(response => response.data)
  },
}
