import FacilitiesPage from '@/modules/facility/views/facilities-page/FacilitiesPage.vue'
import FacilityCreatePage from '@/modules/facility/views/facility-create-page/FacilityCreatePage.vue'
import FacilityEditPage from '@/modules/facility/views/facility-edit-page/FacilityEditPage.vue'
import FacilityDetailRoutes from '@/modules/facility/views/facility-detail-page/router'
import FacilityDetailPage from '@/modules/facility/views/facility-detail-page/FacilityDetailPage.vue'

export default [
  {
    path: '/facilities',
    name: 'facilities',
    component: FacilitiesPage,
    meta: {
      pageTitle: 'Facilities',
      breadcrumb: [
        {
          text: 'Facilities',
          to: '/facilities',
          active: true,
        },
      ],
    },
  },
  {
    path: '/facilities/:slug',
    name: 'facility.detail',
    redirect: { name: 'facility.detail.overview' },
    component: FacilityDetailPage,
    meta: {
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facilities',
          to: '/facilities',
          active: false,
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
    children: [
      ...FacilityDetailRoutes,
    ],
  },
  {
    path: '/facilities/:slug/edit',
    name: 'facility.edit',
    component: FacilityEditPage,
    meta: {
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facilities',
          to: '/facilities',
          active: false,
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/facilities/create',
    name: 'facility-new',
    component: FacilityCreatePage,
  },
]
