import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import auth from '../modules/auth/store'
import course from '../modules/course/store'
// import courseDate from '../modules/course-date/store'
import employee from '../modules/employee/store'
import establishment from '../modules/establishment/store'
import event from '../modules/event/store'
import facility from '../modules/facility/store'
import news from '../modules/news/store'
import organization from '../modules/organization/store'
import profile from '../modules/profile/store'
import publicPage from '../modules/public-page/store'
import settings from '../modules/settings/store'
import substituteRequest from '../modules/substitute-request/store'
import statistics from '../modules/statistics/store'
import team from '../modules/team/store'
import workloud from '../modules/workloud/store'

Vue.use(Vuex)

export default new Vuex.Store({
// export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    // modules
    auth,
    course,
    // courseDate,
    employee,
    establishment,
    event,
    facility,
    news,
    organization,
    profile,
    publicPage,
    settings,
    substituteRequest,
    statistics,
    team,
    workloud,
  },
  strict: process.env.DEV,
})
