export const FETCH_FACILITIES = 'FETCH_FACILITIES'
export const FETCH_FACILITY_BY_SLUG = 'FETCH_FACILITY_BY_SLUG'
export const CREATE_FACILITY = 'CREATE_FACILITY'
export const FETCH_FACILITY = 'FETCH_FACILITY'
export const FETCH_FACILITY_COURSE_DATES = 'FETCH_FACILITY_COURSE_DATES'
export const FETCH_FACILITY_EMPLOYEES = 'FETCH_FACILITY_EMPLOYEES'
export const FETCH_FACILITY_FACILITIES = 'FETCH_FACILITY_FACILITIES'
export const FETCH_FACILITY_EVENTS = 'FETCH_FACILITY_EVENTS'
export const UPDATE_FACILITY = 'UPDATE_FACILITY'
export const STORE_FACILITY = 'STORE_FACILITY'
export const FETCH_FACILITIES_FILTERS = 'FETCH_FACILITIES_FILTERS'
