<template>
  <div>
    <facility-detail-header
      v-if="facility"
      :facility-data="facility"
    />
    <div v-else>
      Loading...
    </div>
    <router-view />
  </div>
</template>

<script>
import { FETCH_FACILITY } from '@/modules/facility/store/action-types'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
import FacilityDetailHeader from './components/FacilityDetailHeader.vue'

export default {
  components: { FacilityDetailHeader },
  setup() {
    const { route } = useRouter()
    const facility = ref(null)

    const loadFacility = async () => {
      store.dispatch(FETCH_FACILITY, route.value.params.slug)
        .then(res => {
          facility.value = res.data.data
        })
    }

    loadFacility()
    return {
      facility,
    }
  },
}
</script>

<style scoped>

</style>
