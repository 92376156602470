<template>
  <div>
    <b-row>
      <!--      <b-col-->
      <!--        cols="12"-->
      <!--        md="6"-->
      <!--      >-->
      <!--        <organization-welcome />-->
      <!--      </b-col>-->
      <b-col
        cols="12"
        md="6"
      >
        <organization-status />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <establishment-recently-visited-card />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <SubstituteRequestsUrgentCard />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <CourseDatesUpcomingCard />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <EventsUpcomingCard />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <CourseOverviewCard />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <NewsLatestCard />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <FeedbackLatestCard />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <PollLatestCard />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <PollsRecentlyCreatedCard />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
} from 'bootstrap-vue'
import EstablishmentRecentlyVisitedCard
  from '@/modules/establishment/components/establishment-recently-visited-card/EstablishmentRecentlyVisitedCard.vue'
import SubstituteRequestsUrgentCard
  from '@/modules/substitute-request/components/substitute-requests-urgent-card/SubstituteRequestsUrgentCard.vue'
import CourseDatesUpcomingCard
  from '@/modules/course-date/components/course-dates-upcoming-card/CourseDatesUpcomingCard.vue'
import NewsLatestCard from '@/modules/news/components/news-latest-card/NewsLatestCard.vue'
import FeedbackLatestCard from '@/modules/feedback/components/feedback-latest-card/FeedbackLatestCard.vue'
import EventsUpcomingCard from '@/modules/event/components/events-upcoming-card/EventsUpcomingCard.vue'
import PollLatestCard from '@/modules/poll/components/poll-latest-card/PollLatestCard.vue'
import PollsRecentlyCreatedCard from '@/modules/poll/components/polls-recently-created-card/PollsRecentlyCreatedCard.vue'
import CourseOverviewCard from '@/modules/course/components/course-overview-card/CourseOverviewCard.vue'
import OrganizationStatus from '@/modules/organization/components/OrganizationStatus.vue'
// import OrganizationWelcome from '@/modules/organization/components/OrganizationWelcome.vue'

export default {
  name: 'DashboardPage',
  components: {
    OrganizationStatus,
    // OrganizationWelcome,
    CourseOverviewCard,
    PollsRecentlyCreatedCard,
    PollLatestCard,
    EventsUpcomingCard,
    FeedbackLatestCard,
    NewsLatestCard,
    CourseDatesUpcomingCard,
    SubstituteRequestsUrgentCard,
    EstablishmentRecentlyVisitedCard,
    BRow,
    BCol,
  },
}
</script>

<style scoped>

</style>
