import facilityService from '@/modules/facility/services'
import { SET_FACILITY_DETAIL_SLUG } from '@/modules/facility/store/mutation-types'
import {
  FETCH_FACILITIES,
  FETCH_FACILITY_BY_SLUG,
  CREATE_FACILITY,
  STORE_FACILITY,
  FETCH_FACILITIES_FILTERS,
  FETCH_FACILITY,
  UPDATE_FACILITY,
  FETCH_FACILITY_COURSE_DATES,
  FETCH_FACILITY_EMPLOYEES,
  FETCH_FACILITY_FACILITIES, FETCH_FACILITY_EVENTS,
} from './action-types'

export default {
  [FETCH_FACILITIES]: (context, params) => new Promise((resolve, reject) => {
    facilityService.getFacilities(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_FACILITY_BY_SLUG]: (context, slug) => new Promise((resolve, reject) => {
    facilityService.getFacilityBySlug(slug)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_FACILITY]: (context, slug) => new Promise((resolve, reject) => {
    facilityService.getFacility(slug)
      .then(response => {
        context.commit(SET_FACILITY_DETAIL_SLUG, slug)
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),

  [FETCH_FACILITY_COURSE_DATES]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getFacilityDetailSlug
    facilityService.getCourseDates({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_FACILITY_EMPLOYEES]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getFacilityDetailSlug
    facilityService.getEmployees({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_FACILITY_FACILITIES]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getFacilityDetailSlug
    facilityService.getFacilitiesChildren({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_FACILITY_EVENTS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getFacilityDetailSlug
    facilityService.getEvents({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [UPDATE_FACILITY]: (context, args) => new Promise((resolve, reject) => {
    facilityService.patchFacility(args)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [CREATE_FACILITY]: (context, data) => new Promise((resolve, reject) => {
    facilityService.createFacility(data)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),

  [STORE_FACILITY]: (context, data) => new Promise((resolve, reject) => {
    facilityService.storeFacility(data)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_FACILITIES_FILTERS]: (context, data) => new Promise((resolve, reject) => {
    facilityService.getFacilitiesFilters(data)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
