export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGIN_FACEBOOK = 'AUTH_LOGIN_FACEBOOK'
export const AUTH_REGISTER = 'AUTH_REGISTER'
export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS'
export const AUTH_RESET = 'AUTH_RESET'
export const AUTH_SEND_RESET_LINK = 'AUTH_SEND_RESET_LINK'
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD'
