import NewsPage from '@/modules/news/views/news-page/NewsPage.vue'
import NewsCreatePage from '@/modules/news/views/news-create-page/NewsCreatePage.vue'
import NewsEditPage from '@/modules/news/views/news-edit-page/NewsEditPage.vue'

export default [
  {
    path: '/news',
    name: 'news',
    component: NewsPage,
    meta: {
      pageTitle: 'News',
      breadcrumb: [
        {
          text: 'News',
          to: '/news',
          active: true,
        },
      ],
    },
  },
  {
    path: '/news/create',
    name: 'news-new',
    component: NewsCreatePage,
  },
  {
    path: '/news/:slug/edit',
    name: 'news.edit',
    component: NewsEditPage,
    meta: {
      pageTitle: 'News',
      breadcrumb: [
        {
          text: 'News',
          to: '/news',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
    children: [
    ],
  },
]
