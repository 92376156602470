<template>
  <div>name: 'AdversitementPage'</div>
</template>

<script>
export default {
  name: 'AdversitementPage',
}
</script>

<style scoped>

</style>
