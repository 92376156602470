import { useToast } from 'vue-toastification/composition'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import { FETCH_ESTABLISHMENTS } from '@/modules/establishment/store/action-types'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useEstablishments() {
  const toast = useToast()

  const refEstablishmentListTable = ref(null)

  const tableColumns = [
    { key: 'name', sortable: true },
    // { key: 'place', sortable: true },
    { key: 'place.address', sortable: true },
    { key: 'status_label', sortable: true },
    { key: 'actions' },
  ]

  const perPage = ref(10)
  const totalEstablishments = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const statusFilter = ref(null)
  // Filter
  const statusOptions = [
    { label: 'Requested', value: 'requested' },
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Deactivated', value: 'deactivated' },
  ]

  const dataMeta = computed(() => {
    const localItemsCount = refEstablishmentListTable.value ? refEstablishmentListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEstablishments.value,
    }
  })

  const refetchData = () => {
    refEstablishmentListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchEstablishments = (ctx, callback) => {
    store
      .dispatch(FETCH_ESTABLISHMENTS, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data

        callback(data)
        totalEstablishments.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching establishment list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // const establishments = ref(null)
  //
  // const fetchEstablishments = () => {
  //   this.$store.dispatch(FETCH_MY_ESTABLISHMENTS, {
  //
  //   })
  //     .then(response => {
  //       this.establishments = response.data
  //     })
  // }
  //
  return {
    tableColumns,
    perPage,
    currentPage,
    totalEstablishments,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refEstablishmentListTable,

    fetchEstablishments,
    refetchData,
    //   establishments,
    statusFilter,
    statusOptions,
  }
}
