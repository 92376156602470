<template>
  <div class="auth-wrapper  px-2 wrapper">
    <div class="py-2 wrapper-inner">
      <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >
        <b-card
          no-body
        >
          <b-card-header>
            <div>
              <b-card-title>
                Choose your organization
              </b-card-title>
            </div>
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="18"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item
                @click="loadMyOrganizations"
              >
                <feather-icon
                  icon="RotateCwIcon"
                  size="18"
                  class="mr-1"
                />
                Reload
              </b-dropdown-item>
              <b-dropdown-item
                @click="onLogout"
              >
                <feather-icon
                  icon="LogOutIcon"
                  size="18"
                  class="mr-1"
                />
                Logout
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>
          <b-card-body
            v-if="myOrganizations"
          >
            <div v-if="myOrganizations.data.length === 0">
              <div class="mb-1">
                You aren't an employee of an organization.
              </div>
            </div>
            <div
              v-for="(organization, index) in myOrganizations.data"
              :key="organization.uuid"
              class="d-flex justify-content-between align-items-center"
              :class="index === 0 ? 'mt-0' : 'mt-1'"
            >
              <b-media no-body>
                <b-media-aside class="mr-75">
                  <b-img
                    rounded
                    size="42"
                    :src="organization.avatar_thumbnail_url"
                    alt="browser img"
                  />
                </b-media-aside>
                <b-media-body>
                  <h6 class="mt-2 text-primary">
                    {{ organization.name }}
                  </h6>
                  <small class="text-muted">
                    {{ organization.address }}
                  </small>
                  <b-badge
                    v-if="organization.my_employee_is_active"
                    :variant="organization.my_employee_state"
                  >
                    ACTIVE
                  </b-badge>
                </b-media-body>
              </b-media>
              <div class="d-flex align-items-center">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="primary"
                  class="btn-icon ml-auto"
                  size="sm"
                  @click="setCurrentOrganization(organization)"
                >
                  <span>Select</span>
                </b-button>
              </div>
            </div>

            <b-card-text class="text-center mt-2">
              <b-link :to="{ name:'login' }">
                <feather-icon icon="ChevronLeftIcon" /> Logout
              </b-link>
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardBody,
  BCardHeader,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BButton,
  BBadge, BCardText,
  BLink,
} from 'bootstrap-vue'
import { FETCH_MY_ORGANIZATIONS, ORGANIZATION_SWITCH } from '@/modules/organization/store/action-types'
import Ripple from 'vue-ripple-directive'
import { AUTH_LOGOUT } from '@/modules/auth/store/action-types'

export default {
  name: 'OrganizationChoosePage',
  components: {
    BCard,
    BCardTitle,
    BCardBody,
    BCardHeader,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BButton,
    BBadge,
    BCardText,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showLoading: false,
    }
  },
  computed: {
    myOrganizations() {
      return this.$store.getters.getMyOrganizations
    },
  },
  mounted() {
    // if (!this.myOrganizations) {
    this.loadMyOrganizations()
    // }
  },
  methods: {
    async loadMyOrganizations() {
      this.showLoading = true
      const args = {
        // page: this.currentPage,
        // perPage: 4,
      }
      await this.$store.dispatch(FETCH_MY_ORGANIZATIONS, args)
        .finally(() => {
          this.showLoading = false
        })
    },
    setCurrentOrganization(organization) {
      this.$store.dispatch(ORGANIZATION_SWITCH, organization)
        .then(() => {
          this.$router.push({ name: 'dashboard' })
        })
    },
    onLogout() {
      this.$store.dispatch(AUTH_LOGOUT)
    },
  },
}
</script>

<style scoped>
.wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper-inner {
  max-width: 400px;
}
</style>
