import AdvertisementModule from '@/modules/advertisement/AdvertisementModule.vue'
import AdvertisementPage from '@/modules/advertisement/views/advertisement-page/AdvertisementPage.vue'

export default [
  {
    path: '/advertisements',
    component: AdvertisementModule,
    children: [
      {
        path: '/',
        name: 'advertisements',
        component: AdvertisementPage,
        meta: {
          pageTitle: 'Advertisements',
          breadcrumb: [
            {
              text: 'Advertisements',
              to: '/advertisements',
              active: true,
            },
          ],
        },
      },
    ],
  },
]
