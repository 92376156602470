<template>
  <!-- form -->
  <validation-observer ref="registerForm">
    <b-form
      class="auth-register-form mt-2"
      @submit.prevent="register"
    >
      <!-- username -->
      <b-form-group
        label="Username"
        label-for="username"
      >
        <validation-provider
          #default="{ errors }"
          name="username"
          rules="required"
        >
          <b-form-input
            v-model="username"
            :state="errors.length > 0 ? false:null"
            placeholder="Username"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- email -->
      <b-form-group
        label="Email"
        label-for="email"
      >
        <validation-provider
          #default="{ errors }"
          name="email"
          rules="required|email"
        >
          <b-form-input
            v-model="emailValue"
            :state="errors.length > 0 ? false:null"
            type="email"
            placeholder="Email"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- password -->
      <b-form-group
        label="Password"
        label-for="password"
      >
        <validation-provider
          #default="{ errors }"
          name="Password"
          rules="required"
        >
          <b-input-group
            class="input-group-merge"
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <b-form-input
              v-model="password"
              :type="passwordFieldType"
              :state="errors.length > 0 ? false:null"
              class="form-control-merge"
              placeholder="············"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIcon"
                class="cursor-pointer"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- password confirm -->
      <b-form-group
        label="Password confirm"
        label-for="password_confirm"
      >
        <validation-provider
          #default="{ errors }"
          name="Password confirm"
          rules="required"
        >
          <b-input-group
            class="input-group-merge"
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <b-form-input
              v-model="passwordConfirm"
              :type="passwordFieldType"
              :state="errors.length > 0 ? false:null"
              class="form-control-merge"
              placeholder="············"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIcon"
                class="cursor-pointer"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- checkbox -->
      <b-form-group>
        <b-form-checkbox
          id="register-privacy-policy"
          v-model="status"
          name="register-privacy-policy"
          value="accepted"
          unchecked-value="not_accepted"
          required
        >
          I agree to
          <b-link>privacy policy & terms</b-link>
        </b-form-checkbox>
      </b-form-group>

      <!-- submit button -->
      <b-button
        variant="primary"
        block
        type="submit"
      >
        Request
      </b-button>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox, BButton, BLink,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, email } from '@core/utils/validations/validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { AUTH_REGISTER } from '../store/action-types'

export default {
  name: 'RegisterForm',
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BButton,
    BLink,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      emailValue: process.env.NODE_ENV === 'development' ? 'markus@besportyapp.com' : null,
      username: process.env.NODE_ENV === 'development' ? 'crusher_markus' : null,
      password: process.env.NODE_ENV === 'development' ? 'password' : null,
      passwordConfirm: process.env.NODE_ENV === 'development' ? 'password' : null,
      status: '',

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    register() {
      if (this.$refs.registerForm.validate()) {
        const data = {
          username: this.username,
          email: this.emailValue,
          password: this.password,
          password_confirm: this.passwordConfirm,
        }
        this.loading = true
        this.$store.dispatch(AUTH_REGISTER, data)
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Welcome ${response.data.user.full_name || response.data.user.username}`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'You have successfully logged. Now you can start to manage!',
              },
            })
            this.$router.push({ name: 'home' })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Register Failed',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
            this.$refs.registerForm.setErrors(error.response.data.errors)
          })
      }
    },
  },
}
</script>

<style scoped>

</style>
