import EventsPage from '@/modules/event/views/events-page/EventsPage.vue'

export default [
  {
    path: '/events/',
    name: 'events',
    component: EventsPage,
    meta: {
      pageTitle: 'Events',
      breadcrumb: [
        {
          text: 'Events',
          to: '/events',
          active: true,
        },
      ],
    },
  },
]
