<template>
  <b-row>
    <b-col
      cols="12"
      md="6"
    >
      Settings
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },
}
</script>

<style scoped>

</style>
