import axios from 'axios'

export default {
  getEmployees(params) {
    return axios({
      url: 'employees', params, data: null, method: 'GET',
    })
  },
  inviteEmployee(email) {
    return axios({
      url: 'employees/invite', params: email, data: null, method: 'GET',
    })
  },
  updateEmployee(data) {
    return axios({
      url: `employees/${data.employee.uuid}`, params: null, data: data.data, method: 'PATCH',
    })
  },
  getEmployeesFilters() {
    return axios({
      url: 'employees/filters', params: null, data: null, method: 'GET',
    })
  },
}
