<template>
  <b-card
    no-body
  >
    <facility-detail-header-info-card :facility-data="facilityData" />
    <facility-detail-header-tabs :facility-data="facilityData" />
  </b-card>
</template>
<script>
import {
  BCard,
} from 'bootstrap-vue'
import FacilityDetailHeaderInfoCard
  from '@/modules/facility/views/facility-detail-page/components/FacilityDetailHeaderInfoCard.vue'
import FacilityDetailHeaderTabs
  from '@/modules/facility/views/facility-detail-page/components/FacilityDetailHeaderTabs.vue'

export default {
  components: {
    FacilityDetailHeaderTabs,
    FacilityDetailHeaderInfoCard,
    BCard,
  },
  props: {
    facilityData: {
      type: Object,
      required: true,
    },
  },
}
</script>
