import FaqModule from '../FaqModule.vue'
import FaqPage from '../views/FaqPage.vue'

export default [
  {
    path: '/faqs',
    component: FaqModule,
    children: [
      {
        path: '/',
        name: 'faqs',
        component: FaqPage,
      },
    ],
  },
]
