<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="user.avatar_big_thumbnail_url"
          text="avatar"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ user.full_name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Username -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Username"
            label-for="username"
          >
            <b-form-input
              id="username"
              v-model="user.username"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <!-- Field: First Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="First name"
            label-for="first-name"
          >
            <b-form-input
              id="first-name"
              v-model="user.first_name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Last Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Last name"
            label-for="last-name"
          >
            <b-form-input
              id="last-name"
              v-model="user.last_name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="user.email"
              type="email"
            />
          </b-form-group>
        </b-col> -->
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="onUpdate"
    >
      Save Changes
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="onReset"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { PROFILE_UPDATE } from '@/modules/profile/store/action-types'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
  },
  // props: {
  //   userData: {
  //     type: Object,
  //     required: true,
  //   },
  // },
  computed: {
    user() {
      return this.$store.getters.profile
    },
  },
  setup(props) {
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      avatarText,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  methods: {
    onReset(event) {
      event.preventDefault()
    },
    onUpdate(event) {
      event.preventDefault()
      // const self = this
      // self.loading = true;
      // self.errors = [];
      const data = {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        // username: this.user.username,
        // email: self.email,
      }
      this.$store.dispatch(PROFILE_UPDATE, data)
      // self.callStoreProfileUpdate(data);
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
