import axios from 'axios'

export default {
  getNews(params) {
    return axios({
      url: 'news', params, data: null, method: 'GET',
    })
  },
  getNewsItem(slug) {
    return axios({
      url: `news/slug/${slug}`, params: null, data: null, method: 'GET',
    })
  },
  storeNews(data) {
    return axios({ url: 'news', data, method: 'POST' })
  },
  updateNews(data) {
    return axios({
      url: `news/${data.params.uuid}`, params: null, data: data.data, method: 'PATCH',
    })
  },
  deleteNews(uuid) {
    return axios({ url: `news/${uuid}`, data: null, method: 'DELETE' })
  },
}
