<template>
  <!-- form -->
  <validation-observer
    ref="refFormObserver"
    #default="{invalid,handleSubmit}"
  >
    <b-form
      class="auth-reset-password-form mt-2"
      @submit.prevent="handleSubmit(onSubmit)"
    >
      <!-- email -->
      <b-form-group
        label="Email"
        label-for="forgot-password-email"
      >
        <validation-provider
          #default="{ errors }"
          vid="email"
          name="Email"
          rules="required|email"
        >
          <b-form-input
            id="forgot-password-email"
            v-model="userEmail"
            name="forgot-password-email"
            autocomplete="email"
            disabled
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- password -->
      <b-form-group
        label="New Password"
        label-for="reset-password-new"
      >
        <validation-provider
          #default="{ errors }"
          name="Password"
          vid="Password"
          rules="required|password"
        >
          <b-input-group
            class="input-group-merge"
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <b-form-input
              id="reset-password-new"
              v-model="password"
              :type="password1FieldType"
              :state="errors.length<=0"
              class="form-control-merge"
              name="reset-password-new"
              autocomplete="new-password"
              placeholder="············"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="password1ToggleIcon"
                @click="togglePassword1Visibility"
              />
            </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </validation-provider>
      </b-form-group>

      <!-- confirm password -->
      <b-form-group
        label-for="reset-password-confirm"
        label="Confirm Password"
      >
        <validation-provider
          #default="{ errors }"
          name="Confirm Password"
          rules="required|confirmed:Password"
        >
          <b-input-group
            class="input-group-merge"
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <b-form-input
              id="reset-password-confirm"
              v-model="cPassword"
              :type="password2FieldType"
              :state="errors.length<=0"
              name="reset-password-confirm"
              class="form-control-merge"
              autocomplete="new-password"
              placeholder="············"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="password2ToggleIcon"
                @click="togglePassword2Visibility"
              />
            </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </validation-provider>
      </b-form-group>

      <!-- submit button -->
      <b-button
        type="submit"
        variant="primary"
        block
        :disabled="invalid || loading"
        @click="onSubmit"
      >
        <b-spinner
          v-if="loading"
          small
        />
        Set New Password
      </b-button>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
} from 'bootstrap-vue'
import { required } from '@core/utils/validations/validations'
import { $themeConfig } from '@themeConfig'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { AUTH_RESET_PASSWORD } from '@/modules/auth/store/action-types'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ResetPasswordForm',
  components: {
    BButton,
    BSpinner,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: this.$route.query.email,
      cPassword: '',
      password: '',
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    const loading = ref(false)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {
    })

    return {
      appName,
      appLogoImage,
      loading,

      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (!success) return
        this.loading = true
        this.$store.dispatch(AUTH_RESET_PASSWORD, {
          token: this.$route.params.token,
          email: this.userEmail,
          password: this.password,
          password_confirmation: this.cPassword,
        })
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Reset Password successfully!',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
            this.$router.push({ name: 'login' })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Reset Password Failed',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
            this.$refs.refFormObserver.setErrors(error.response.data.errors)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
  },
}
</script>

<style scoped>

</style>
