<template>
  <b-form>
    <b-row>
      <b-col
        v-for="socialField in socialInputs"
        :key="socialField.dataField"
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          :label="socialField.label"
          :label-for="socialField.dataField"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                size="16"
                :icon="socialField.icon"
              />
            </b-input-group-prepend>
            <b-form-input
              :id="socialField.dataField"
              v-model="userDataSocial[socialField.dataField]"
              type="url"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col class="mt-2">
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="onUpdate"
        >
          Save Changes
        </b-button>
        <b-button
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="onReset"
        >
          Reset
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import { PROFILE_UPDATE } from '@/modules/profile/store/action-types'
import store from '../../../store'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
  },
  computed: {
    user() {
      return this.$store.getters.profile
    },
  },
  setup() {
    const userDataSocial = {
      social_facebook: store.state.profile.social_facebook,
      social_instagram: store.state.profile.social_instagram,
      social_snapchat: store.state.profile.social_snapchat,
      social_twitter: store.state.profile.social_twitter,
      social_youtube: store.state.profile.social_youtube,
      social_google: store.state.profile.social_google,
    }

    // ? dataField is field name of `userDataSocial` object
    const socialInputs = [
      {
        icon: 'FacebookIcon',
        dataField: 'social_facebook',
        label: 'Facebook',
      },
      {
        icon: 'InstagramIcon',
        dataField: 'social_instagram',
        label: 'Instagram',
      },
      {
        icon: 'SnapchatIcon',
        dataField: 'social_snapchat',
        label: 'Snapchat',
      },
      {
        icon: 'TwitterIcon',
        dataField: 'social_twitter',
        label: 'Twitter',
      },
      {
        icon: 'YoutubeIcon',
        dataField: 'social_youtube',
        label: 'YouTube',
      },
      {
        icon: 'GoogleIcon',
        dataField: 'social_google',
        label: 'Google',
      },

    ]

    return {
      userDataSocial,
      socialInputs,
    }
  },
  methods: {
    onReset(event) {
      event.preventDefault()
    },
    onUpdate(event) {
      event.preventDefault()
      // const data = {
      //   first_name: this.user.first_name,
      //   last_name: this.user.last_name,
      //   // username: this.user.username,
      //   // email: self.email,
      // }
      this.$store.dispatch(PROFILE_UPDATE, this.userDataSocial)
    },
  },
}
</script>

<style>

</style>
