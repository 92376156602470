import { FETCH_COURSE_DATES, FETCH_COURSE_DATES_FILTERS } from '@/modules/course-date/store/action-types'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCourseDates() {
  const toast = useToast()

  const refCourseDateListTable = ref(null)

  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'start_time', sortable: true },
    { key: 'establishment', sortable: true },
    { key: 'facility', sortable: true },
    { key: 'sportCategoryName', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]

  const perPage = ref(10)
  const totalCourseDates = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('status')
  const isSortDirDesc = ref(true)

  const statusFilter = ref(null)

  const timeOptions = ref([])

  // [
  //   { label: 'Today', value: 'today' },
  //   { label: 'Tomorrow', value: 'tomorrow' },
  //   { label: 'This week', value: 'this-week' },
  //   { label: 'This weekend', value: 'this-weekend' },
  //   { label: 'Next week', value: 'next-week' },
  //   { label: 'This month', value: 'this-month' },
  //   { label: 'Next month', value: 'next-month' },
  //   { label: 'Past', value: 'past' },
  //   { label: 'Upcoming', value: 'upcoming' },
  //   { label: 'All', value: 'all' },
  // ]
  const timeFilter = ref(null)

  const sportCategoryFilter = ref(null)

  const courseFilter = ref(null)
  const courseOptions = ref([])

  const establishmentsFilter = ref(null)
  const establishmentsOptions = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refCourseDateListTable.value ? refCourseDateListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCourseDates.value,
    }
  })

  const refetchData = () => {
    refCourseDateListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, timeFilter, sportCategoryFilter, courseFilter, establishmentsFilter], () => {
    refetchData()
  })

  const fetchCourseDates = (ctx, callback) => {
    store
      .dispatch(FETCH_COURSE_DATES, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        time: timeFilter.value,
        status: statusFilter.value,
        sportCategory: sportCategoryFilter.value,
        course: courseFilter.value,
        establishments: establishmentsFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data

        callback(data)
        totalCourseDates.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching course date list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchCoursesFilters = async () => {
    store.dispatch(FETCH_COURSE_DATES_FILTERS)
      .then(response => {
        courseOptions.value = response.data.courses
        timeOptions.value = response.data.times
        establishmentsOptions.value = response.data.establishments
      })
  }

  fetchCoursesFilters()

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // Filter
  const statusOptions = [
    { label: 'Draft', value: 'draft' },
    { label: 'Pending', value: 'pending' },
    { label: 'Published', value: 'published' },
  ]

  const sportCategoryOptions = [
    { label: 'Fitness', value: 'fitness' },
  ]

  return {
    tableColumns,
    perPage,
    currentPage,
    totalCourseDates,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCourseDateListTable,

    fetchCourseDates,
    refetchData,

    statusFilter,
    statusOptions,
    timeFilter,
    timeOptions,
    sportCategoryFilter,
    sportCategoryOptions,
    establishmentsFilter,
    establishmentsOptions,
    courseFilter,
    courseOptions,
  }
}
