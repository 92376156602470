import {
  FETCH_NEWS,
  STORE_NEWS,
  REMOVE_NEWS,
  FETCH_NEWSITEM, UPDATE_NEWS,
} from '@/modules/news/store/action-types'
import newsService from '@/modules/news/services'

export default {
  [FETCH_NEWS]: (context, params) => new Promise((resolve, reject) => {
    newsService.getNews(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_NEWSITEM]: (context, slug) => new Promise((resolve, reject) => {
    newsService.getNewsItem(slug)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [STORE_NEWS]: (context, data) => new Promise((resolve, reject) => {
    newsService.storeNews(data)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [UPDATE_NEWS]: (context, data) => new Promise((resolve, reject) => {
    newsService.updateNews(data)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [REMOVE_NEWS]: (context, uuid) => new Promise((resolve, reject) => {
    newsService.deleteNews(uuid)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
