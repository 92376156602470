<template>
  <b-card
    v-if="myOrganization"
    no-body
  >
    <b-card-header>
      <div>
        <b-card-title>
          Organization Status
        </b-card-title>
      </div>
    </b-card-header>
    <b-card-body>
      {{ myOrganization.status_label }}
    </b-card-body>
  </b-card>
</template>

<script>

import {
  BCard, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { FETCH_MY_ORGANIZATION } from '@/modules/organization/store/action-types'

export default {
  name: 'OrganizationStatus',
  components: {
    BCardHeader,
    BCard,
    BCardTitle,
    BCardBody,
  },
  computed: {
    myOrganization() {
      return this.$store.getters.getMyOrganization
    },
  },
  mounted() {
    // if (!this.myOrganizations) {
    // this.fetchMyOrganization()
    // }
  },
  methods: {
    async fetchMyOrganization() {
      this.showLoading = true
      await this.$store.dispatch(FETCH_MY_ORGANIZATION)
        .finally(() => {
          this.showLoading = false
        })
    },
  },
  setup() {
    const showLoading = ref(false)

    return {
      showLoading,
    }
  },
}
</script>
