<template>
  <div>

    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Personal Information
      </h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>

        <!-- bio -->
        <b-col cols="12">
          <b-form-group
            label="Bio"
            label-for="bio-area"
          >
            <b-form-textarea
              id="bio-area"
              v-model="user.about"
              rows="4"
              placeholder="Your bio data here..."
            />
          </b-form-group>
        </b-col>
        <!--/ bio -->

        <!-- Field: Birth Date -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Birth Date"
            label-for="birth-date"
          >
            <flat-pickr
              v-model="user.birth_date"
              class="form-control"
              :config="{ dateFormat: 'Y-m-d'}"
              placeholder="YYYY-MM-DD"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Phone -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Phone"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="user.phone"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Website -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Website"
            label-for="website"
          >
            <b-form-input
              id="website"
              v-model="user.website"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Language -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Language"
            label-for="language"
          >
            <v-select
              v-model="user.locale"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="languageOptions"
              :clearable="false"
              input-id="language"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Gender -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Gender"
            label-for="gender"
            label-class="mb-1"
          >
            <b-form-radio-group
              id="gender"
              v-model="user.gender"
              :options="genderOptions"
              value="male"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Contact Options -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Contact Options"
            label-for="contact-options"
            label-class="mb-1"
          >
            <b-form-checkbox-group
              id="contact-options"
              v-model="user.contactOptions"
              :options="contactOptionsOptions"
            />
          </b-form-group>

          <b-form-group
            label="Contact Options"
            label-for="contact-options"
            label-class="mb-1"
          >
            <b-form-checkbox-group
              id="contact-options"
            >
              <b-form-checkbox
                v-model="user.contact_mail"
                name="contact_mail"
              >Email</b-form-checkbox>
              <b-form-checkbox
                v-model="user.contact_msg"
                name="contact_msg"
              >Message</b-form-checkbox>
              <b-form-checkbox
                v-model="user.contact_phone"
                name="contact_phone"
              >Phone</b-form-checkbox>
            </b-form-checkbox-group>

          </b-form-group>
        </b-col>
      </b-row>

      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <feather-icon
          icon="MapPinIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Address
        </h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">

        <!-- Field: Address -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Address"
            label-for="address"
          >
            <b-form-input
              id="address"
              v-model="user.address"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Postcode -->
        <b-col
          cols="4"
          md="2"
          lg="4"
        >
          <b-form-group
            label="Postcode"
            label-for="postcode"
          >
            <b-form-input
              id="postcode"
              v-model="user.postal_code"
              type="number"
              placeholder="597626"
            />
          </b-form-group>
        </b-col>

        <!-- Field: City -->
        <b-col
          cols="8"
          md="4"
          lg="4"
        >
          <b-form-group
            label="City"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="user.city"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Country -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Country"
            label-for="country"
          >
            <b-form-input
              id="country"
              v-model="user.country"
              placeholder="Germany"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onUpdate"
          >
            Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onReset"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BFormCheckbox, BButton, BFormTextarea,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import { PROFILE_UPDATE } from '@/modules/profile/store/action-types'
import store from '../../../store'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, flatPickr, BFormInput, vSelect, BFormRadioGroup, BFormCheckboxGroup, BFormCheckbox, BButton, BFormTextarea,
  },
  computed: {
    user() {
      return this.$store.getters.profile
    },
  },
  setup() {
    const userDataInfo = ref({
      gender: store.state.profile.gender,
      contactOptions: ['Email', 'Message'],
      address: store.state.profile.address,
      postal_code: store.state.profile.postal_code,
      city: store.state.profile.city,
      country: store.state.profile.country,
    })

    const languageOptions = [
      { text: 'English', value: 'en' },
      { text: 'Spanish', value: 'es' },
      { text: 'German', value: 'de' },
    ]
    // [
    //   'English',
    //   'Spanish',
    //   'German',
    // ]

    const genderOptions = [
      { text: 'Male', value: 'm' },
      { text: 'Female', value: 'f' },
    ]

    const contactOptionsOptions = [
      { text: 'Mail', value: store.state.profile.contact_mail },
      { text: 'Message', value: store.state.profile.contact_msg },
      { text: 'Phone', value: store.state.profile.contact_phone },
    ] // ['Email', 'Message', 'Phone']

    return {
      userDataInfo,
      languageOptions,
      genderOptions,
      contactOptionsOptions,
    }
  },
  methods: {
    onReset(event) {
      event.preventDefault()
    },
    onUpdate(event) {
      event.preventDefault()
      // const data = {
      //   first_name: this.user.first_name,
      //   last_name: this.user.last_name,
      //   // username: this.user.username,
      //   // email: self.email,
      // }
      this.$store.dispatch(PROFILE_UPDATE, this.userDataInfo)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
