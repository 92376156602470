import axios from 'axios'

export default {
  getFacilities(params) {
    return axios({
      url: 'facilities', params, data: null, method: 'GET',
    })
  },
  getFacilityBySlug(slug) {
    return axios({
      url: 'facilities', params: { slug }, data: null, method: 'GET',
    })
  },
  createFacility(data) {
    return axios({ url: 'facilities/create', data, method: 'GET' })
  },
  getFacility(slug) {
    return axios({
      url: `facilities/slug/${slug}`, params: null, data: null, method: 'GET',
    })
  },
  getCourseDates({ slug, params }) {
    return axios({
      url: `facilities/slug/${slug}/course-dates`, params, data: null, method: 'GET',
    })
  },
  getEmployees({ slug, params }) {
    return axios({
      url: `facilities/slug/${slug}/employees`, params, data: null, method: 'GET',
    })
  },
  getFacilitiesChildren({ slug, params }) {
    return axios({
      url: `facilities/slug/${slug}/facilities`, params, data: null, method: 'GET',
    })
  },
  getEvents({ slug, params }) {
    return axios({
      url: `facilities/slug/${slug}/events`, params, data: null, method: 'GET',
    })
  },
  patchFacility(args) {
    return axios({
      url: `facilities/${args.uuid}`, data: args.data, method: 'PATCH',
    })
  },
  storeFacility(data) {
    return axios({ url: 'facilities', data, method: 'POST' })
  },
  deleteFacility(uuid) {
    return axios({ url: `facilities/${uuid}`, data: null, method: 'DELETE' })
  },
  getFacilitiesFilters() {
    return axios({
      url: 'facilities/filters', params: null, data: null, method: 'GET',
    })
  },
}
