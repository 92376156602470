import {
  FETCH_SUBSTITUTE_REQUESTS,
  DELETE_SUBSTITUTE_REQUESTS,
} from '@/modules/substitute-request/store/action-types'

import substituteRequestService from '../services'

export default {
  [FETCH_SUBSTITUTE_REQUESTS]: (context, params) => new Promise((resolve, reject) => {
    substituteRequestService.getSubstituteRequests(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [DELETE_SUBSTITUTE_REQUESTS]: (context, userData) => new Promise((resolve, reject) => {
    substituteRequestService.deleteSubstituteRequests(userData)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
