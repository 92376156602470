import axios from 'axios'

export default {
  getMyOrganizations(params) {
    return axios({
      url: 'organizations', params, data: null, method: 'GET',
    })
  },
  getOrganization(slug) {
    return axios({
      url: `organizations/${slug}`, params: null, data: null, method: 'GET',
    })
  },
  switchOrganization(organization) {
    return axios({
      url: `organizations/${organization.uuid}/switch`, params: null, data: null, method: 'GET',
    })
  },
  storeOrganization(organizationData) {
    return axios({
      url: 'organizations', data: organizationData, method: 'POST',
    })
  },
}
