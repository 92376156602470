import FeedbackModule from '@/modules/feedback/FeedbackModule.vue'
import FeedbackPage from '@/modules/feedback/views/feedback-page/FeedbackPage.vue'

export default [
  {
    path: '/feedback',
    component: FeedbackModule,
    children: [
      {
        path: '/',
        name: 'feedback',
        component: FeedbackPage,
        meta: {
          pageTitle: 'Feedback',
          breadcrumb: [
            {
              text: 'Feedback',
              to: '/feedback',
              active: true,
            },
          ],
        },
      },
    ],
  },
]
