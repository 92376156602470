import LoginPage from '@/modules/auth/views/login-page/LoginPage.vue'
import RegisterOrganizationPage from '@/modules/auth/views/RegisterOrganizationPage.vue'
import ForgotPasswordPage from '../views/ForgotPasswordPage.vue'
import ResetPasswordPage from '../views/ResetPasswordPage.vue'

import AuthModule from '../AuthModule.vue'
import RegisterPage from '../views/RegisterPage.vue'

export default [
  {
    path: '/',
    redirect: { name: 'login' },
    component: AuthModule,
    children: [
      {
        path: '/login',
        name: 'login',
        component: LoginPage,
        // beforeEnter: ifNotAuthenticated,
        meta: {
          layout: 'full',
          resource: 'Auth',
          redirectIfLoggedIn: true,
        },
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPasswordPage,
        meta: {
          layout: 'full',
          resource: 'Auth',
          redirectIfLoggedIn: true,
        },
      },
      {
        path: '/reset-password',
        name: 'reset-password',
        component: ResetPasswordPage,
        meta: {
          layout: 'full',
          resource: 'Auth',
          redirectIfLoggedIn: true,
        },
      },
      {
        path: '/register',
        name: 'register',
        component: RegisterPage,
        meta: {
          layout: 'full',
          resource: 'Auth',
          redirectIfLoggedIn: true,
        },
      },
      {
        path: '/request-organization',
        name: 'request-organization',
        component: RegisterOrganizationPage,
        meta: {
          layout: 'full',
          resource: 'Auth',
          redirectIfLoggedIn: true,
        },
      },
    ],
  },
]
