import {
  FETCH_MY_ORGANIZATIONS,
  FETCH_MY_ORGANIZATION, ORGANIZATION_SWITCH,
  STORE_ORGANIZATION,
} from './action-types'

import {
  SET_MY_ORGANIZATIONS,
  SET_MY_ORGANIZATION,
} from './mutation-types'
import organizationService from '../services'

export default {
  [STORE_ORGANIZATION]: (context, organizationData) => new Promise((resolve, reject) => {
    organizationService.storeOrganization(organizationData)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_MY_ORGANIZATIONS]: (context, params) => new Promise((resolve, reject) => {
    organizationService.getMyOrganizations(params)
      .then(response => {
        context.commit(SET_MY_ORGANIZATIONS, response.data)
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_MY_ORGANIZATION]: (context, slug) => new Promise((resolve, reject) => {
    organizationService.getOrganization(slug)
      .then(response => {
        context.commit(SET_MY_ORGANIZATION, response.data.data)
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [ORGANIZATION_SWITCH]: ({ commit }, organization) => new Promise((resolve, reject) => {
    commit(SET_MY_ORGANIZATION, organization)
    organizationService.switchOrganization(organization)
    //   organizationService.switchOrganization(slug)
    //   // axios({url: 'employees/' + item.uuid + '', data: { is_active: 1}, method: 'PATCH'});
    //   axios({ url: `organizations/${item.uuid}/switch`, data: null, method: 'GET' })
      .then(response => {
        // localStorage.setItem('organization', JSON.stringify(organization))
        commit(SET_MY_ORGANIZATION, organization)
        //       dispatch(ORGANIZATIONS_FETCH)
        //       // let organizations = response.data.data;
        //       // commit(ORGANIZATIONS_SUCCESS, organizations);
        //
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
