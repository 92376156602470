<template>
  <div>
    <b-card
      class="mb-0"
      no-body
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              variant="primary"
              :to="{ name: 'feedbacks-new'}"
            >
              Add Record
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                class="invoice-filter-select"
                placeholder="Select Status"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        ref="refEstablishmentListTable"
        :items="feedbacks"
        responsive
        :fields="tableColumns"
        primary-key="uuid"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">

            <!-- Dropdown -->
            <b-dropdown
              :right="$store.state.appConfig.isRTL"
              no-caret
              variant="link"
            >

              <template #button-content>
                <feather-icon
                  class="align-middle text-body"
                  icon="MoreVerticalIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item :to="{ name: 'feedbacks.detail', params: { username: data.item.slug } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'feedbacks.edit', params: { username: data.item.slug } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import { FETCH_FEEDBACKS } from '@/modules/feedback/store/action-types'
import {
  BCard,
  BRow,
  BCol,
  BFormInput, BDropdown, BDropdownItem, BTable,
  BButton,
} from 'bootstrap-vue'
import { ref, watch, computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BButton,

    vSelect,
  },
  data() {
    return {
      feedbacks: null,
    }
  },
  setup() {
    // Use toast
    const toast = useToast()

    const refNewsListTable = ref(null)

    const tableColumns = [
      { key: 'title', sortable: true },
      { key: 'body', sortable: false },
      { key: 'actions' },
    ]

    const perPage = ref(10)
    const totalNews = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref(null)

    const dataMeta = computed(() => {
      const localItemsCount = refNewsListTable.value ? refNewsListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: refNewsListTable.value,
      }
    })

    const refetchData = () => {
      refNewsListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, statusFilter], () => {
      refetchData()
    })

    const fetchNews = (ctx, callback) => {
      store
        .dispatch(FETCH_FEEDBACKS, {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          status: statusFilter.value,
        })
        .then(response => {
          const { invoices, total } = response.data

          callback(invoices)
          totalNews.value = total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching invoices' list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const statusOptions = [
      'requested',
      'confirmed',
      'rejected',
    ]

    return {
      tableColumns,
      perPage,
      statusFilter,
      statusOptions,
      fetchNews,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refNewsListTable,
      //   feedbacks,
    }
  },
  mounted() {
    if (!this.feedbacks) {
      this.loadNews()
    }
  },
  methods: {
    async loadNews() {
      this.showLoading = true
      const args = {}
      await this.$store.dispatch(FETCH_FEEDBACKS, args)
        .then(response => {
          this.feedbacks = response.data.data
          this.metaData = response.data.meta
        })
        .finally(() => {
          this.showLoading = false
        })
    },
    handlePageChange(value) {
      this.currentPage = value
      this.loadNews()
    },
  },
}
</script>

<style lang="scss" scoped>
.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
