import {
  SET_MY_ORGANIZATIONS,
  SET_MY_ORGANIZATION,
} from './mutation-types'

export default {
  [SET_MY_ORGANIZATIONS]: (state, organizations) => {
    state.myOrganizations = organizations
  },
  [SET_MY_ORGANIZATION]: (state, organization) => {
    state.myOrganization = organization
  },
}
