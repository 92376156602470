<template>
  <div id="user-profile">
    <profile-header :user-data="user" />
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <!-- about suggested page and twitter feed -->
        <b-col
          lg="3"
          cols="12"
          order="2"
          order-lg="1"
        >
          <profile-about />
          <!--          <profile-feed :feed="profileData.feeds" />-->
        </b-col>
        <!--/ about suggested page and twitter feed -->

        <!-- post -->
        <!--        <b-col-->
        <!--          lg="6"-->
        <!--          cols="12"-->
        <!--          order="1"-->
        <!--          order-lg="2"-->
        <!--        >-->
        <!--          <profile-post :posts="profileData.post" />-->
        <!--        </b-col>-->
        <!-- post -->

        <!-- load more  -->
        <b-col
          cols="12"
          order="4"
        >
          <profile-bottom />
        </b-col>
        <!--/ load more  -->

      </b-row>
    </section>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ProfileAbout from '@/modules/profile/views/profile-page/components/ProfileAbout.vue'
import ProfileHeader from './components/ProfileHeader.vue'
// import ProfileFeed from './components/ProfileFeed.vue'
// import ProfilePost from './components/ProfilePost.vue'
import ProfileBottom from '../../components/ProfileBottom.vue'
// import ProfileLatestPhotos from './components/ProfileLatestPhotos.vue'

/* eslint-disable global-require */
export default {
  name: 'ProfilePage',
  components: {
    ProfileAbout,
    ProfileHeader,
    // ProfileFeed,
    // ProfilePost,
    // ProfileLatestPhotos,
    ProfileBottom,
    BRow,
    BCol,

  },
  data() {
    return {
      profileData: {
      },
    }
  },
  computed: {
    user() {
      return this.$store.getters.profile
    },
    // errors() {
    //     return this.$store.getters.errors;
    // }
    // ...mapFields([
    //     'user.first_name',
    //     'user.last_name',
    //     'user.username',
    //     // ...
    // ]),
  },
  created() {
  },
  methods: {
  },
}
</script>

<style lang="scss" >
  @import '@core/scss/vue/pages/page-profile.scss';
</style>
