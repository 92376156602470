import {
  PROFILE_FETCH, PROFILE_UPDATE, PROFILE_CHANGE_EMAIL,
} from './action-types'
import profileService from '../services'
import {
  PROFILE_UPDATE_FAILURE, PROFILE_SET, PROFILE_UPDATE_SUCCESS,
} from './mutation-types'

export default {
  [PROFILE_FETCH]: ({ commit }) => new Promise((resolve, reject) => {
    profileService.getProfile()
      .then(response => {
        const user = response.data.data
        localStorage.setItem('user', JSON.stringify(user))
        commit(PROFILE_SET, { user })
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [PROFILE_UPDATE]: ({ commit }, data) => new Promise((resolve, reject) => {
    profileService.updateProfile(data)
      .then(response => {
        commit(PROFILE_UPDATE_SUCCESS, response.data.data)
        resolve(response)
      })
      .catch(err => {
        commit(PROFILE_UPDATE_FAILURE, { errors: err.response.data.errors })
        reject(err)
      })
  }),
  [PROFILE_CHANGE_EMAIL]: (context, data) => new Promise((resolve, reject) => {
    profileService.changeEmail(data)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
