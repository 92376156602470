import axios from 'axios'

export default {
  login(data) {
    return axios({ url: 'login', data, method: 'POST' })
  },
  apiAuthenticate(accessToken) {
    return axios.post('login/facebook/token', { accessToken })
  },
  async loginFacebook() {
    const { authResponse } = await new Promise(FB.login)
    if (!authResponse) return null
    return this.apiAuthenticate(authResponse.accessToken)
  },
  register(data) {
    return axios({ url: 'register', data, method: 'POST' })
  },
  logout() {
    return axios({ url: 'logout', method: 'GET' })
  },
  sendResetLink(data) {
    return axios({ url: 'password/email', data, method: 'POST' })
  },
  resetPassword(data) {
    return axios({ url: 'password/reset', data, method: 'POST' })
  },
}
