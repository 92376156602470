<template>
  <div>
    <teams-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
      :sport-category-filter.sync="sportCategoryFilter"
      :sport-category-options="sportCategoryOptions"
    />
    <!-- Table Container Card -->
    <b-card
      class="mb-0"
      no-body
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            cols="12"
            md="6"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :clearable="false"
              :options="perPageOptions"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                class="ml-1"
                variant="primary"
                @click="refetchData"
              >
                <span class="text-nowrap">Reload</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refTeamListTable"
        class="position-relative"
        :items="fetchTeams"
        responsive
        :fields="tableColumns"
        primary-key="uuid"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Name -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.image_thumbnail_url"
                :text="data.item.name"
                :to="{ name: 'team.detail', params: { slug: data.item.slug } }"
              />
            </template>
            <b-link
              :to="{ name: 'team.detail', params: { slug: data.item.slug } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">@{{ data.item.slug }}</small><br>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${data.item.state}`"
            class="text-capitalize"
            pill
          >
            {{ data.item.status_label }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!-- Dropdown -->
          <b-dropdown
            no-caret
            variant="link"
          >

            <template #button-content>
              <feather-icon
                class="align-middle text-body"
                icon="MoreVerticalIcon"
                size="16"
              />
            </template>

            <b-dropdown-item @click="delete(data.item)">
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.total }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalTeams"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BPagination, BRow, BTable,
  BBadge,
  BMedia,
  BAvatar,
  BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, watch, computed } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import TeamsFilters
  from '@/modules/team/views/teams-page/components/TeamsFilters.vue'
// eslint-disable-next-line import/named
import { FETCH_TEAMS } from '@/modules/team/store/action-types'

export default {
  name: 'TeamsPage',
  components: {
    TeamsFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BDropdown,
    BDropdownItem,
    BBadge,
    vSelect,
  },
  setup() {
    const toast = useToast()

    const refTeamListTable = ref(null)

    const tableColumns = [
      { key: 'name', sortable: true },
      { key: 'is_private_label', sortable: true },
      { key: 'status', sortable: true },
      { key: 'actions' },
    ]

    const perPage = ref(10)
    const totalTeams = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('status')
    const isSortDirDesc = ref(false)

    const statusFilter = ref(null)
    const sportCategoryFilter = ref(null)

    const dataMeta = computed(() => {
      const localItemsCount = refTeamListTable.value ? refTeamListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalTeams.value,
      }
    })

    const refetchData = () => {
      refTeamListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, statusFilter, sportCategoryFilter], () => {
      refetchData()
    })

    const fetchTeams = (ctx, callback) => {
      store
        .dispatch(FETCH_TEAMS, {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          status: statusFilter.value,
        })
        .then(response => {
          const { data, meta } = response.data

          callback(data)
          // dataMeta.value = meta
          totalTeams.value = meta.total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching team list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    // Filter
    const statusOptions = [
      { label: 'Draft', value: 'active' },
      { label: 'Pending', value: 'completed' },
      { label: 'Published', value: 'canceled' },
    ]

    const sportCategoryOptions = [
      { label: 'Fitness', value: 'fitness' },
    ]

    return {
      fetchTeams,
      tableColumns,
      perPage,
      currentPage,
      totalTeams,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTeamListTable,

      refetchData,

      statusFilter,
      statusOptions,
      sportCategoryOptions,
      sportCategoryFilter,
    }
  },
}
</script>

<style scoped>

</style>
