import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
// eslint-disable-next-line import/no-cycle
import { isUserLoggedIn } from '@/auth/utils'

import CourseManagementPage from '@/views/course-management-page/CourseManagementPage.vue'
import CalendarPage from '@/views/calendar-page/CalendarPage.vue'
import LeadsPage from '@/views/leads-page/LeadsPage.vue'
import TicketsPage from '@/views/tickets-page/TicketsPage.vue'
import AdvertisementRoutes from '../modules/advertisement/router'
import AuthRoutes from '../modules/auth/router'
import CourseRoutes from '../modules/course/router'
import CourseDateRoutes from '../modules/course-date/router'
import DashboardRoutes from '../modules/dashboard/router'
import EmployeeRoutes from '../modules/employee/router'
import EstablishmentRoutes from '../modules/establishment/router'
import EventRoutes from '../modules/event/router'
import FacilityRoutes from '../modules/facility/router'
import FaqRoutes from '../modules/faq/router'
import FeedbackRoutes from '../modules/feedback/router'
import NewsRoutes from '../modules/news/router'
import OrganizationRoutes from '../modules/organization/router'
import ProfileRoutes from '../modules/profile/router'
import PublicPageRoutes from '../modules/public-page/router'
import SettingsRoutes from '../modules/settings/router'
import SubstituteRequestRoutes from '../modules/substitute-request/router'
import StatisticsRoutes from '../modules/statistics/router'
import TeamRoutes from '../modules/team/router'
import WorkloudRoutes from '../modules/workloud/router'
// eslint-disable-next-line import/no-cycle
// import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/course-management/',
      name: 'course-management',
      component: CourseManagementPage,
    },
    {
      path: '/calendar/',
      name: 'calendar',
      component: CalendarPage,
    },
    {
      path: '/leads',
      name: 'leads',
      component: LeadsPage,
    },
    {
      path: '/tickets',
      name: 'tickets',
      component: TicketsPage,
    },
    ...AdvertisementRoutes,
    ...AuthRoutes,
    ...CourseRoutes,
    ...CourseDateRoutes,
    ...DashboardRoutes,
    ...EmployeeRoutes,
    ...EstablishmentRoutes,
    ...EventRoutes,
    ...FaqRoutes,
    ...FacilityRoutes,
    ...FeedbackRoutes,
    ...NewsRoutes,
    ...OrganizationRoutes,
    ...ProfileRoutes,
    ...PublicPageRoutes,
    ...SettingsRoutes,
    ...SubstituteRequestRoutes,
    ...StatisticsRoutes,
    ...TeamRoutes,
    ...WorkloudRoutes,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  // console.log(`to.name:${JSON.stringify(to.name)}`)
  // console.log(`_:${JSON.stringify(_)}`)
  // console.log(`next:${JSON.stringify(next)}`)
  const isLoggedIn = isUserLoggedIn()
  // eslint-disable-next-line no-use-before-define,no-shadow
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })

    // // Redirect if logged in and no organization choosen
    // const organizationStoreObj = store.state.organization
    // // console.log(`organizationStoreObj:${JSON.stringify(organizationStoreObj)}`)
    // const { myOrganization } = organizationStoreObj // !!localStorage.getItem('organization')
    // console.log(`!myOrganization:${!myOrganization}`)
    // if (!myOrganization) return next({ name: 'organization-choose' })

    // // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' })
  }

  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  // if (isLoggedIn && !myOrganization) {
  //   console.log('redirect to organization-choose')
  //   return next({ name: 'organization-choose' })
  // }
  // if (to.meta.redirectIfLoggedIn && isLoggedIn && isOrganizationChoosen) {
  //   return next({ name: 'dashboard' })
  // }
  // if (to.meta.redirectIfLoggedIn && isLoggedIn && isOrganizationChoosen) {
  //   next({ name: 'dashboard' })
  // } else
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next({ name: 'organization-choose' })
  }

  return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
