import TeamDetailOverview
  from '@/modules/team/views/team-detail-page/views/team-detail-page-overview/TeamDetailOverview.vue'
import TeamDetailEvents
  from '@/modules/team/views/team-detail-page/views/team-detail-page-events/TeamDetailEvents.vue'
import TeamDetailSettings
  from '@/modules/team/views/team-detail-page/views/team-detail-page-settings/TeamDetailSettings.vue'
import TeamDetailPolls
  from '@/modules/team/views/team-detail-page/views/team-detail-page-polls/TeamDetailPolls.vue'
import TeamDetailMembers from '@/modules/team/views/team-detail-page/views/team-detail-page-members/TeamDetailMembers.vue'
import TeamDetailFollowersPage
  from '@/modules/team/views/team-detail-page/views/team-detail-followers-page/TeamDetailFollowersPage.vue'
import TeamDetailHostingsPage
  from '@/modules/team/views/team-detail-page/views/team-detail-hostings-page/TeamDetailHostingsPage.vue'

export default [
  {
    path: '',
    name: 'team.detail.overview',
    component: TeamDetailOverview,
    meta: {
      pageTitle: 'Team',
      breadcrumb: [
        {
          text: 'Teams',
          to: '/teams',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: 'members',
    name: 'team.detail.members',
    component: TeamDetailMembers,
    meta: {
      pageTitle: 'Team',
      breadcrumb: [
        {
          text: 'Teams',
          to: '/teams',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Members',
          to: '/teams/members',
          active: true,
        },
      ],
    },
  },
  {
    path: 'events',
    name: 'team.detail.events',
    component: TeamDetailEvents,
    meta: {
      pageTitle: 'Team',
      breadcrumb: [
        {
          text: 'Teams',
          to: '/teams',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Events',
          to: '/teams/events',
          active: true,
        },
      ],
    },
  },
  {
    path: 'polls',
    name: 'team.detail.polls',
    component: TeamDetailPolls,
    meta: {
      pageTitle: 'Team',
      breadcrumb: [
        {
          text: 'Teams',
          to: '/teams',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Polls',
          to: '/teams/polls',
          active: true,
        },
      ],
    },
  },
  {
    path: 'followers',
    name: 'team.detail.followers',
    component: TeamDetailFollowersPage,
    meta: {
      pageTitle: 'Team',
      breadcrumb: [
        {
          text: 'Teams',
          to: '/teams',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Followers',
          to: '/teams/followers',
          active: true,
        },
      ],
    },
  },
  {
    path: 'hostings',
    name: 'team.detail.hostings',
    component: TeamDetailHostingsPage,
    meta: {
      pageTitle: 'Team',
      breadcrumb: [
        {
          text: 'Teams',
          to: '/teams',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Hostings',
          to: '/teams/hostings',
          active: true,
        },
      ],
    },
  },
  {
    path: 'settings',
    name: 'team.detail.settings',
    component: TeamDetailSettings,
    meta: {
      pageTitle: 'Team',
      breadcrumb: [
        {
          text: 'Teams',
          to: '/teams',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Settings',
          to: '/teams/settings',
          active: true,
        },
      ],
    },
  },
]
