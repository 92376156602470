<template>
  <div>
    <establishment-detail-header
      v-if="establishment"
      :establishment-data="establishment"
    />
    <div v-else>
      Loading...
    </div>
    <router-view />
  </div>
</template>

<script>
import { FETCH_ESTABLISHMENT } from '@/modules/establishment/store/action-types'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import EstablishmentDetailHeader from './components/EstablishmentDetailHeader.vue'

export default {
  components: { EstablishmentDetailHeader },
  setup() {
    const { route } = useRouter()
    const establishment = ref(null)

    const loadEstablishment = async () => {
      store.dispatch(FETCH_ESTABLISHMENT, route.value.params.slug)
        .then(res => {
          establishment.value = res.data.data
        })
    }

    loadEstablishment()

    return {
      establishment,
    }
  },
}
</script>

<style scoped>

</style>
