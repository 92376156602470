import CoursesPage from '@/modules/course/views/courses-page/CoursesPage.vue'

export default [
  {
    path: '/courses',
    name: 'courses',
    component: CoursesPage,
    meta: {
      pageTitle: 'Courses',
      breadcrumb: [
        {
          text: 'Courses',
          to: '/courses',
          active: true,
        },
      ],
    },
  },
]
