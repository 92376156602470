import axios from 'axios'

export default {
  getSubstituteRequests(params) {
    return axios({
      url: 'substitute-requests', params, data: null, method: 'GET',
    })
  },
  deleteSubstituteRequests(uuid) {
    return axios({
      url: `substitute-requests/${uuid}`, params: null, data: null, method: 'DELETE',
    })
  },
}
