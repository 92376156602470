import { FETCH_COURSE_DATES, FETCH_COURSE_DATES_FILTERS } from '@/modules/course-date/store/action-types'
import courseDateService from '@/modules/course-date/services'

export default {
  [FETCH_COURSE_DATES]: (context, params) => new Promise((resolve, reject) => {
    courseDateService.getCourseDates(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_COURSE_DATES_FILTERS]: () => new Promise((resolve, reject) => {
    courseDateService.getCoursesFilters()
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
