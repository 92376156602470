<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <b-card no-body>
              <b-card-header class="flex-column align-items-start">
                <b-card-title>Add New Facility</b-card-title>
                <b-card-text class="text-muted mt-25">
                  Search after your location and submit
                </b-card-text>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                      ><b-form-group
                        label="Name"
                        label-for="name"
                      >
                        <b-form-input
                          id="name"
                          v-model="facilityData.name"
                        />
                      </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                      ><b-form-group
                        label="Details"
                        label-for="details"
                      >
                        <b-form-input
                          id="details"
                          v-model="facilityData.details"
                        />
                      </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          label="Status"
                          label-for="status"
                        >
                          <b-form-select
                            v-model="facilityData.status"
                            :options="statuses"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody, BCardHeader, BCardText, BCardTitle,
  BCol, BForm, BFormGroup, BFormInput, BRow, BFormSelect,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'

export default {
  name: 'FacilityCreatePage',
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BFormSelect,
    ValidationObserver,
  },
  setup() {
    const facilityData = ref({
      name: null,
      details: null,
      status: null,
    })
    const statuses = [
    ]

    return {
      facilityData,
      statuses,
    }
  },
}
</script>

<style scoped>

</style>
