import { FETCH_EVENTS } from '@/modules/event/store/action-types'
import eventService from '@/modules/event/services'

export default {
  [FETCH_EVENTS]: (context, params) => new Promise((resolve, reject) => {
    eventService.getEvents(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
