import { computed, ref, watch } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { FETCH_ESTABLISHMENT_FACILITIES } from '@/modules/establishment/store/action-types'

export default function useEstablishmentFacilities() {
  const toast = useToast()

  const refFacilityListTable = ref(null)

  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]

  const perPage = ref(10)
  const totalFacilities = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('status')
  const isSortDirDesc = ref(true)

  const statusFilter = ref(null)
  const roleFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refFacilityListTable.value ? refFacilityListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalFacilities.value,
    }
  })

  const refetchData = () => {
    refFacilityListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchFacilities = async (ctx, callback) => {
    store
      .dispatch(FETCH_ESTABLISHMENT_FACILITIES, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data

        callback(data)
        totalFacilities.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching establishment facility list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // Filter
  const statusOptions = [
    { label: 'Requested', value: 'requested' },
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Deactivated', value: 'deactivated' },
  ]

  return {
    tableColumns,
    perPage,
    currentPage,
    totalFacilities,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refFacilityListTable,

    fetchFacilities,
    refetchData,

    statusFilter,
    statusOptions,
  }
}
