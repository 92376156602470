import axios from 'axios'

export default {
  get(slug) {
    return axios({
      url: `/${slug}`, params: null, data: null, method: 'GET',
    })
  },
  getCourses(params) {
    return axios({
      url: 'courses', params, data: null, method: 'GET',
    })
  },
  getCoursesFilters() {
    return axios({
      url: 'courses/filters', params: null, data: null, method: 'GET',
    })
  },
}
