import axios from 'axios'
import { PROFILE_SET } from '@/modules/profile/store/mutation-types'
import authService from '../services'
import {
  AUTH_ERROR,
  AUTH_LOGOUT,
  AUTH_REQUEST,
  AUTH_LOGIN,
  AUTH_LOGIN_FACEBOOK,
  AUTH_RESET,
  AUTH_SUCCESS,
  AUTH_REGISTER,
  AUTH_SEND_RESET_LINK,
  AUTH_RESET_PASSWORD,
} from './action-types'
import router from '../../../router'

// import {resetState} from "../../../store";

export default {
  [AUTH_LOGIN]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(AUTH_REQUEST)
    authService.login(data)
      .then(response => {
        const tokenType = response.data.token_type
        const accessToken = response.data.access_token
        const expiresAt = response.data.expires_at
        const {
          user,
          // organization,
          // organizations,
        } = response.data
        const token = `${tokenType} ${accessToken}`
        localStorage.setItem('token', token)
        localStorage.setItem('expiresAt', expiresAt)
        localStorage.setItem('user', JSON.stringify(user))
        // localStorage.setItem('organization', JSON.stringify(organization))
        // localStorage.setItem('organizations', JSON.stringify(organizations))
        axios.defaults.headers.common.Authorization = token
        commit(AUTH_SUCCESS, { token })
        commit(PROFILE_SET, { user })
        resolve(response)
      })
      .catch(err => {
        commit(AUTH_ERROR, err)
        // localStorage.removeItem("token");
        reject(err)
      })
  }),
  [AUTH_LOGIN_FACEBOOK]: ({ commit }) => new Promise((resolve, reject) => {
    authService.loginFacebook()
      .then(response => {
        const tokenType = response.data.token_type
        const accessToken = response.data.access_token
        const expiresAt = response.data.expires_at
        const {
          user, organization, organizations,
        } = response.data
        const token = `${tokenType} ${accessToken}`
        localStorage.setItem('token', token)
        localStorage.setItem('expiresAt', expiresAt)
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('organization', JSON.stringify(organization))
        localStorage.setItem('organizations', JSON.stringify(organizations))
        axios.defaults.headers.common.Authorization = token
        commit(AUTH_SUCCESS, { token })
        commit(PROFILE_SET, { user })
        resolve(response)
      })
      .catch(err => {
        commit(AUTH_ERROR, err)
        // localStorage.removeItem("token");
        reject(err)
      })
  }),
  [AUTH_REGISTER]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(AUTH_REQUEST)
    authService.register(data)
      .then(response => {
        const tokenType = response.data.token_type
        const accessToken = response.data.access_token
        const expiresAt = response.data.expires_at
        const { user } = response.data
        const token = `${tokenType} ${accessToken}`
        localStorage.setItem('token', token)
        localStorage.setItem('expiresAt', expiresAt)
        localStorage.setItem('user', JSON.stringify(user))
        axios.defaults.headers.common.Authorization = token
        commit(AUTH_SUCCESS, token)
        commit(PROFILE_SET, { user })
        resolve(response)
      })
      .catch(err => {
        commit(AUTH_ERROR, err)
        reject(err)
      })
  }),
  [AUTH_LOGOUT]: ({ commit }) => new Promise((resolve, reject) => {
    commit(AUTH_REQUEST)
    return authService.logout()
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
      .finally(() => {
        commit(AUTH_RESET)
        localStorage.clear()
        router.push({ name: 'login' })
      })
  }),
  [AUTH_SEND_RESET_LINK]: (context, email) => new Promise((resolve, reject) => {
    authService.sendResetLink(email)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [AUTH_RESET_PASSWORD]: (context, data) => new Promise((resolve, reject) => {
    authService.resetPassword(data)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
