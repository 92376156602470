import SubstituteRequestModule from '@/modules/substitute-request/SubstituteRequestModule.vue'
import SubstituteRequestsPage from '@/modules/substitute-request/views/substitute-requests-page/SubstituteRequestsPage.vue'

export default [
  {
    path: '/substitute-requests',
    component: SubstituteRequestModule,
    children: [
      {
        path: '/',
        name: 'substitute-requests',
        component: SubstituteRequestsPage,
        meta: {
          pageTitle: 'Substitute Requests',
          breadcrumb: [
            {
              text: 'Substitute Requests',
              to: '/substitute-requests',
              active: true,
            },
          ],
        },
      },
    ],
  },
]
