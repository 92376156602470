<template>
  <b-card>
    <b-tabs
      pills
    >
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Account</span>
        </template>
        <profile-edit-tab-account class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <profile-edit-tab-information class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Social -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Social</span>
        </template>
        <profile-edit-tab-social class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Change password -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Change password</span>
        </template>
        <profile-edit-tab-change-password class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Change email -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="MailIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Change email</span>
        </template>
        <profile-edit-tab-change-email class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { BCard, BTabs, BTab } from 'bootstrap-vue'
import ProfileEditTabAccount from './components/ProfileEditTabAccount.vue'
import ProfileEditTabInformation from './components/ProfileEditTabInformation.vue'
import ProfileEditTabSocial from './components/ProfileEditTabSocial.vue'
import ProfileEditTabChangePassword from './components/ProfileEditTabChangePassword.vue'
import ProfileEditTabChangeEmail from './components/ProfileEditTabChangeEmail.vue'

export default {
  name: 'ProfileEditPage',
  components: {
    BTab,
    BCard,
    BTabs,

    ProfileEditTabAccount,
    ProfileEditTabInformation,
    ProfileEditTabSocial,
    ProfileEditTabChangePassword,
    ProfileEditTabChangeEmail,
  },
}
</script>

<style scoped>

</style>
