<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <b-card-title class="mb-1">
          Reset Password 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Your new password must be different from previously used passwords
        </b-card-text>

        <reset-password-form />

        <p class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>

      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
import {
  BCard, BCardTitle, BCardText,
  BLink,
} from 'bootstrap-vue'
import ResetPasswordForm from '@/modules/auth/components/ResetPasswordForm.vue'

export default {
  components: {
    ResetPasswordForm,
    BCard,
    BCardTitle,
    BCardText,
    BLink,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
