import ProfileEditPage from '@/modules/profile/views/profile-edit-page/ProfileEditPage.vue'
import ProfilePage from '../views/profile-page/ProfilePage.vue'

export default [
  {
    path: '/',
    name: 'profile',
    component: ProfilePage,
  },
  {
    path: 'edit',
    name: 'profile.edit',
    component: ProfileEditPage,
  },
]
