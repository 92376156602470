import EstablishmentDetailOverview
  from '@/modules/establishment/views/establishment-detail-page/views/establishment-detail-page-overview/EstablishmentDetailOverview.vue'
import EstablishmentDetailEmployees
  from '@/modules/establishment/views/establishment-detail-page/views/establishment-detail-page-employees/EstablishmentDetailEmployees.vue'
import EstablishmentDetailFacilities
  from '@/modules/establishment/views/establishment-detail-page/views/establishment-detail-page-facilities/EstablishmentDetailFacilities.vue'
import EstablishmentDetailCourseDates
  from '@/modules/establishment/views/establishment-detail-page/views/establishment-detail-page-course-dates/EstablishmentDetailCourseDates.vue'
import EstablishmentDetailEvents
  from '@/modules/establishment/views/establishment-detail-page/views/establishment-detail-page-events/EstablishmentDetailEvents.vue'
import EstablishmentDetailSettings
  from '@/modules/establishment/views/establishment-detail-page/views/establishment-detail-page-settings/EstablishmentDetailSettings.vue'

export default [
  {
    path: '',
    name: 'establishment.detail.overview',
    component: EstablishmentDetailOverview,
    meta: {
      pageTitle: 'Establishment',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
          active: false,
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: 'employees',
    name: 'establishment.detail.employees',
    component: EstablishmentDetailEmployees,
    meta: {
      pageTitle: 'Establishment',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
          active: false,
        },
        {
          text: 'Detail',
          active: true,
        },
        {
          text: 'Employees',
          to: '/establishments/employees',
          active: false,
        },
      ],
    },
  },
  {
    path: 'facilities',
    name: 'establishment.detail.facilities',
    component: EstablishmentDetailFacilities,
    meta: {
      pageTitle: 'Establishment',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
          active: false,
        },
        {
          text: 'Detail',
          active: true,
        },
        {
          text: 'Facilities',
          to: '/establishments/facilities',
          active: false,
        },
      ],
    },
  },
  {
    path: 'courses',
    name: 'establishment.detail.course-dates',
    component: EstablishmentDetailCourseDates,
    meta: {
      pageTitle: 'Establishment',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
          active: false,
        },
        {
          text: 'Detail',
          active: true,
        },
        {
          text: 'Courses',
          to: '/establishments/courses',
          active: false,
        },
      ],
    },
  },
  {
    path: 'events',
    name: 'establishment.detail.events',
    component: EstablishmentDetailEvents,
    meta: {
      pageTitle: 'Establishment',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
          active: false,
        },
        {
          text: 'Detail',
          active: true,
        },
        {
          text: 'Events',
          to: '/establishments/events',
          active: false,
        },
      ],
    },
  },
  {
    path: 'settings',
    name: 'establishment.detail.settings',
    component: EstablishmentDetailSettings,
    meta: {
      pageTitle: 'Establishment',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
          active: false,
        },
        {
          text: 'Detail',
          active: true,
        },
        {
          text: 'Settings',
          to: '/establishments/settings',
          active: false,
        },
      ],
    },
  },
]
