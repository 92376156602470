<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <b-card no-body>
              <b-card-header class="flex-column align-items-start">
                <b-card-title>Edit Establishment</b-card-title>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      label="Name"
                      label-for="name"
                    >
                      <b-form-input
                        id="name"
                        v-model="establishment.name"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      type="submit"
                    >
                      Edit Establishment
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow, BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody, BButton, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'
import { FETCH_ESTABLISHMENT, UPDATE_ESTABLISHMENT } from '@/modules/establishment/store/action-types'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { route } = useRouter()
    const establishment = ref({})

    const loadEstablishment = async () => {
      store.dispatch(FETCH_ESTABLISHMENT, route.value.params.slug)
        .then(res => {
          establishment.value = res.data.data
        })
    }

    loadEstablishment()

    const onSubmit = () => {
      store.dispatch(UPDATE_ESTABLISHMENT, {
        uuid: establishment.value.uuid,
        data: { name: establishment.value.name },
      })
    }

    return {
      establishment,
      onSubmit,
    }
  },
}
</script>

<style scoped>

</style>
