import { useToast } from 'vue-toastification/composition'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import { FETCH_EVENTS } from '@/modules/event/store/action-types'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useEvents() {
  const toast = useToast()

  const refEventListTable = ref(null)

  const tableColumns = [
    { key: 'start_time', sortable: true },
    { key: 'name', sortable: true },
    { key: 'place.name', sortable: true },
    { key: 'sportCategory.name', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]

  const perPage = ref(10)
  const totalEvents = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('status')
  const isSortDirDesc = ref(true)

  const statusFilter = ref(null)
  const timeFilter = ref('all')
  const sportCategoryFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refEventListTable.value ? refEventListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEvents.value,
    }
  })

  const refetchData = () => {
    refEventListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, timeFilter, sportCategoryFilter], () => {
    refetchData()
  })

  const fetchEvents = (ctx, callback) => {
    store
      .dispatch(FETCH_EVENTS, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        time: timeFilter.value,
        sportCategory: sportCategoryFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data

        callback(data)
        totalEvents.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching event list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // Filter
  const statusOptions = [
    { label: 'Requested', value: 'requested' },
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Deactivated', value: 'deactivated' },
  ]

  const timeOptions = [
    { label: 'Today', value: 'today' },
    { label: 'Tomorrow', value: 'tomorrow' },
    { label: 'This week', value: 'this-week' },
    { label: 'This weekend', value: 'this-weekend' },
    { label: 'Next week', value: 'next-week' },
    { label: 'This month', value: 'this-month' },
    { label: 'Next month', value: 'next-month' },
    { label: 'Past', value: 'past' },
    { label: 'Upcoming', value: 'upcoming' },
    { label: 'All', value: 'all' },
  ]

  const sportCategoryOptions = [
    { label: 'Fitness', value: 'fitness' },
  ]

  return {
    fetchEvents,
    tableColumns,
    perPage,
    currentPage,
    totalEvents,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refEventListTable,
    refetchData,

    statusFilter,
    statusOptions,
    timeFilter,
    timeOptions,
    sportCategoryOptions,
    sportCategoryFilter,
  }
}
