<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card
      no-body
    >
      <b-card-header>
        <div>
          <b-card-title>
            Latest news
          </b-card-title>
        </div>

        <b-dropdown
          variant="link"
          no-caret
          toggle-class="p-0"
          right
        >
          <template #button-content>
            <feather-icon
              icon="MoreHorizontalIcon"
              size="18"
              class="text-body cursor-pointer"
            />
          </template>
          <b-dropdown-item
            :to="{ name: 'news' }"
          >
            <feather-icon
              icon="ListIcon"
              size="18"
              class="mr-1"
            />
            Show All
          </b-dropdown-item>
          <b-dropdown-item>
            <feather-icon
              icon="PlusIcon"
              size="18"
              class="mr-1"
            />
            New
          </b-dropdown-item>
          <b-dropdown-item>
            <feather-icon
              icon="RotateCwIcon"
              size="18"
              class="mr-1"
            />
            Reload
          </b-dropdown-item>
        </b-dropdown>
      </b-card-header>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardHeader,
  BDropdown,
  BDropdownItem,
  BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BCardHeader,
    BCard,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BOverlay,
  },
  data() {
    return {
      showLoading: false,
    }
  },
}
</script>

<style scoped>

</style>
