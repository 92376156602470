<template>
  <div>
    <team-detail-header
      v-if="team"
      :team-data="team"
    />
    <div v-else>
      Loading...
    </div>
    <router-view />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { FETCH_TEAM } from '@/modules/team/store/action-types'
import TeamDetailHeader from './components/TeamDetailHeader.vue'

export default {
  components: { TeamDetailHeader },
  setup() {
    const { route } = useRouter()
    const team = ref(null)

    const loadTeam = async () => {
      store.dispatch(FETCH_TEAM, route.value.params.slug)
        .then(res => {
          team.value = res.data.data
        })
    }

    loadTeam()

    return {
      team,
    }
  },
}
</script>

<style scoped>

</style>
