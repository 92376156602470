<template>
  <div>name: 'StatisticsPage'</div>
</template>

<script>
export default {
  name: 'StatisticsPage',
}
</script>

<style scoped>

</style>
