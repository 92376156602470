<template>
  <b-tabs
    class="ml-1"
    pills
  >
    <template #tabs-start>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='establishment.detail.overview'"
        :to="{ name: 'establishment.detail.overview'}"
      >
        <span class="d-none d-md-block">Overview</span>
        <feather-icon
          icon="RssIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='establishment.detail.employees'"
        :to="{ name: 'establishment.detail.employees'}"
      >
        <span class="d-none d-md-block">Employees</span>
        <feather-icon
          icon="UsersIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='establishment.detail.coachings'"
        :to="{ name: 'establishment.detail.coachings'}"
      >
        <span class="d-none d-md-block">Coachings</span>
        <feather-icon
          icon="UsersIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='establishment.detail.facilities'"
        :to="{ name: 'establishment.detail.facilities'}"
      >
        <span class="d-none d-md-block">Facilities</span>
        <feather-icon
          icon="CalendarIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='establishment.detail.courses'"
        :to="{ name: 'establishment.detail.courses'}"
      >
        <span class="d-none d-md-block">Courses</span>
        <feather-icon
          icon="CalendarIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='establishment.detail.course-dates'"
        :to="{ name: 'establishment.detail.course-dates'}"
      >
        <span class="d-none d-md-block">Course Dates</span>
        <feather-icon
          icon="CalendarIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='establishment.detail.events'"
        :to="{ name: 'establishment.detail.events'}"
      >
        <span class="d-none d-md-block">Events</span>
        <feather-icon
          icon="CalendarIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='establishment.detail.feedbacks'"
        :to="{ name: 'establishment.detail.feedbacks'}"
      >
        <span class="d-none d-md-block">Feedbacks</span>
        <feather-icon
          icon="CalendarIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='establishment.detail.reviews'"
        :to="{ name: 'establishment.detail.reviews'}"
      >
        <span class="d-none d-md-block">Reviews</span>
        <feather-icon
          icon="CalendarIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='establishment.detail.polls'"
        :to="{ name: 'establishment.detail.polls'}"
      >
        <span class="d-none d-md-block">Polls</span>
        <feather-icon
          icon="CalendarIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='establishment.detail.settings'"
        :to="{ name: 'establishment.detail.settings'}"
      >
        <span class="d-none d-md-block">Settings</span>
        <feather-icon
          icon="SettingsIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
    </template>

  </b-tabs>
</template>

<script>
import { BNavItem, BTabs } from 'bootstrap-vue'

export default {
  components: {
    BTabs,
    BNavItem,
  },
  props: {
    establishmentData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
