import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import moment from 'moment'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueGeolocation from 'vue-browser-geolocation'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
// import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/init-facebook-sdk'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

const EventBus = new Vue()
export default EventBus

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.filter('formatDate', (value, format) => {
  if (value) {
    return moment(String(value)).format(format)
  }
  return value
})

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDwL6bl_ynf7NXBmXav-GvI7Ff8XR9tCXw',
    libraries: 'places',
  },
  installComponents: true,
})

Vue.use(VueGeolocation)
Vue.use(VueGoogleAutocomplete)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
