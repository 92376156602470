import axios from 'axios'

export default {
  getEstablishments(params) {
    return axios({
      url: 'establishments', params, data: null, method: 'GET',
    })
  },
  getEstablishment(slug) {
    return axios({
      url: `establishments/slug/${slug}`, params: null, data: null, method: 'GET',
    })
  },
  patchEstablishment(args) {
    return axios({
      url: `establishments/${args.uuid}`, data: args.data, method: 'PATCH',
    })
  },
  storeEstablishment(data) {
    return axios({ url: 'establishments', data, method: 'POST' })
  },
  deleteEstablishment(uuid) {
    return axios({ url: `establishments/${uuid}`, data: null, method: 'DELETE' })
  },
  getCourseDates({ slug, params }) {
    return axios({
      url: `establishments/slug/${slug}/course-dates`, params, data: null, method: 'GET',
    })
  },
  getEmployees({ slug, params }) {
    return axios({
      url: `establishments/slug/${slug}/employees`, params, data: null, method: 'GET',
    })
  },
  getFacilities({ slug, params }) {
    return axios({
      url: `establishments/slug/${slug}/facilities`, params, data: null, method: 'GET',
    })
  },
  getEvents({ slug, params }) {
    return axios({
      url: `establishments/slug/${slug}/events`, params, data: null, method: 'GET',
    })
  },
}
