import { FETCH_COURSES, FETCH_COURSES_FILTERS } from '@/modules/course/store/action-types'
import courseService from '@/modules/course/services'

export default {
  [FETCH_COURSES]: (context, params) => new Promise((resolve, reject) => {
    courseService.getCourses(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_COURSES_FILTERS]: () => new Promise((resolve, reject) => {
    courseService.getCoursesFilters()
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
