import CourseDatesPage from '@/modules/course-date/views/course-dates-page/CourseDatesPage.vue'

export default [
  {
    path: '/course-dates',
    name: 'course-dates',
    component: CourseDatesPage,
    meta: {
      pageTitle: 'Course Dates',
      breadcrumb: [
        {
          text: 'Course Dates',
          to: '/course-dates',
          active: true,
        },
      ],
    },
  },
]
