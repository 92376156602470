import DashboardPage from '@/modules/dashboard/views/dashboard-page/DashboardPage.vue'

export default [
  {
    path: '/',
    name: 'dashboard',
    component: DashboardPage,
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
]
