<template>
  <b-form>
    <b-row>
      <!-- old password -->
      <b-col md="6">
        <b-form-group
          label="New Email"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="email"
            name="email"
            placeholder="Email"
          />
        </b-form-group>
      </b-col>

      <!-- buttons -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-1 mr-1"
        >
          Save changes
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
          class="mt-1"
        >
          Reset
        </b-button>
      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-form>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      email: '',
    }
  },
}
</script>

<style scoped>

</style>
