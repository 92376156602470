<template>
  <b-tabs
    class="ml-1"
    pills
  >
    <template #tabs-start>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='team.detail.overview'"
        :to="{ name: 'team.detail.overview'}"
      >
        <span class="d-none d-md-block">Overview</span>
        <feather-icon
          icon="RssIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='team.detail.members'"
        :to="{ name: 'team.detail.members'}"
      >
        <span class="d-none d-md-block">Members</span>
        <feather-icon
          icon="CalendarIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='team.detail.followers'"
        :to="{ name: 'team.detail.followers'}"
      >
        <span class="d-none d-md-block">Followers</span>
        <feather-icon
          icon="RssIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='team.detail.hostings'"
        :to="{ name: 'team.detail.hostings'}"
      >
        <span class="d-none d-md-block">Hostings</span>
        <feather-icon
          icon="CalendarIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='team.detail.events'"
        :to="{ name: 'team.detail.events'}"
      >
        <span class="d-none d-md-block">Events</span>
        <feather-icon
          icon="CalendarIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='team.detail.polls'"
        :to="{ name: 'team.detail.polls'}"
      >
        <span class="d-none d-md-block">Polls</span>
        <feather-icon
          icon="CalendarIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='team.detail.settings'"
        :to="{ name: 'team.detail.settings'}"
      >
        <span class="d-none d-md-block">Settings</span>
        <feather-icon
          icon="SettingsIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
    </template>

  </b-tabs>
</template>

<script>
import { BNavItem, BTabs } from 'bootstrap-vue'

export default {
  components: {
    BTabs,
    BNavItem,
  },
  props: {
    teamData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
