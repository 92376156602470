import { useToast } from 'vue-toastification/composition'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import { EMPLOYEE_UPDATE, FETCH_EMPLOYEES, FETCH_EMPLOYEES_FILTERS } from '@/modules/employee/store/action-types'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useEmployees() {
  const toast = useToast()

  const refEmployeeListTable = ref(null)

  const tableColumns = [
    { key: 'user', sortable: true },
    { key: 'role', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]

  const perPage = ref(10)
  const totalEmployees = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('status')
  const isSortDirDesc = ref(true)

  // Filter
  const statusOptions = [
    { label: 'Requested', value: 'requested' },
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Deactivated', value: 'deactivated' },
  ]
  const statusFilter = ref(null)
  const roleOptions = [
    { label: 'User', value: 'user' },
    { label: 'Administrator', value: 'administrator' },
  ]
  const roleFilter = ref(null)
  const establishmentsOptions = ref([])
  const establishmentsFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refEmployeeListTable.value ? refEmployeeListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEmployees.value,
    }
  })

  const refetchData = () => {
    refEmployeeListTable.value.refresh()
  }

  const deactivate = employee => {
    store
      .dispatch(EMPLOYEE_UPDATE, {
        employee,
        data: {
          status: 'deactivate',
        },
      })
      .then(() => {
        refetchData()
      })
  }

  const activate = employee => {
    store
      .dispatch(EMPLOYEE_UPDATE, {
        employee,
        data: {
          status: 'confirm',
        },
      })
      .then(() => {
        refetchData()
      })
  }

  watch([currentPage, perPage, searchQuery, statusFilter, roleFilter, establishmentsFilter], () => {
    refetchData()
  })

  const fetchEmployees = (ctx, callback) => {
    store
      .dispatch(FETCH_EMPLOYEES, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        role: roleFilter.value,
        establishments: establishmentsFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data

        callback(data)
        totalEmployees.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching employee list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const fetchEmployeesFilters = async () => {
    store.dispatch(FETCH_EMPLOYEES_FILTERS)
      .then(response => {
        establishmentsOptions.value = response.data.establishments
      })
  }

  fetchEmployeesFilters()

  return {
    tableColumns,
    perPage,
    currentPage,
    totalEmployees,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refEmployeeListTable,

    fetchEmployees,
    refetchData,
    activate,
    deactivate,

    statusFilter,
    statusOptions,
    roleFilter,
    roleOptions,
    establishmentsFilter,
    establishmentsOptions,
  }
}
