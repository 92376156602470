import authService from '@modules/auth/services'
import Vue from 'vue'

const facebookAppId = process.env.VUE_APP_FACEBOOK_APP_ID

const initFacebookSdk = () => new Promise(resolve => {
  // wait for facebook sdk to initialize before starting the vue app
  // eslint-disable-next-line func-names
  window.fbAsyncInit = function () {
    FB.init({
      appId: facebookAppId,
      cookie: true,
      xfbml: true,
      version: 'v8.0',
    })

    // auto authenticate with the api if already logged in with facebook
    FB.getLoginStatus(({ authResponse }) => {
      if (authResponse) {
        authService.apiAuthenticate(authResponse.accessToken).then(resolve)
      } else {
        resolve()
      }
    })
  };

  // load facebook sdk script
  // eslint-disable-next-line func-names
  (function (d, s, id) {
    let js; const
      fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) { return }
    // eslint-disable-next-line prefer-const
    js = d.createElement(s); js.id = id
    js.src = 'https://connect.facebook.net/en_US/sdk.js'
    fjs.parentNode.insertBefore(js, fjs)
  }(document, 'script', 'facebook-jssdk'))
})

Vue.use(initFacebookSdk)
export default initFacebookSdk
