<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <b-card no-body>
              <b-card-header class="flex-column align-items-start">
                <b-card-title>Add New News</b-card-title>
                <b-card-text class="text-muted mt-25">
                  Send a new to the whole organization, establishments, etc
                </b-card-text>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Title"
                      label-for="title"
                    >
                      <b-form-input
                        id="title"
                        v-model="title"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Body"
                      label-for="body"
                    >
                      <b-form-textarea
                        id="body"
                        v-model="body"
                        placeholder="Body"
                        rows="6"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      type="submit"
                    >
                      Add Establishment
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow, BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'
import { required, integer } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { STORE_NEWS } from '@/modules/news/store/action-types'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  methods: {
    onSubmit() {
      this.$store.dispatch(STORE_NEWS, {
        title: this.title,
        body: this.body,
      })
        .then(() => {
          this.$router.push({ name: 'news' })
        })
    },
  },
  setup() {
    const title = ref('title')
    const body = ref('body')

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {
    })

    return {
      title,
      body,

      // Form

      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,

      required,
      integer,
    }
  },
}
</script>

<style scoped>

</style>
