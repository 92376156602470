<template>
  <b-card
    no-body
  >
    <team-detail-header-info-card :team-data="teamData" />
    <team-detail-header-tabs :team-data="teamData" />
  </b-card>
</template>
<script>
import {
  BCard,
} from 'bootstrap-vue'
import TeamDetailHeaderInfoCard
  from '@/modules/team/views/team-detail-page/components/TeamDetailHeaderInfoCard.vue'
import TeamDetailHeaderTabs
  from '@/modules/team/views/team-detail-page/components/TeamDetailHeaderTabs.vue'

export default {
  components: {
    TeamDetailHeaderTabs,
    TeamDetailHeaderInfoCard,
    BCard,
  },
  props: {
    teamData: {
      type: Object,
      required: true,
    },
  },
}
</script>
