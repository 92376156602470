import axios from 'axios'

export default {
  getCourseDates(params) {
    return axios({
      url: 'course-dates', params, data: null, method: 'GET',
    })
  },
  getCoursesFilters() {
    return axios({
      url: 'course-dates/filters', params: null, data: null, method: 'GET',
    })
  },
}
