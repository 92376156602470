import EstablishmentsPage from '@/modules/establishment/views/establishments-page/EstablishmentsPage.vue'
import EstablishmentDetailPage from '@/modules/establishment/views/establishment-detail-page/EstablishmentDetailPage.vue'
import EstablishmentCreatePage from '@/modules/establishment/views/establishment-create-page/EstablishmentCreatePage.vue'
import EstablishmentDetailRoutes from '@/modules/establishment/views/establishment-detail-page/router'
import EstablishmentEditPage from '@/modules/establishment/views/establishment-edit-page/EstablishmentEditPage.vue'

export default [
  {
    path: '/establishments',
    name: 'establishments',
    component: EstablishmentsPage,
    meta: {
      pageTitle: 'Establishments',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
          active: true,
        },
      ],
    },
  },
  {
    path: '/establishments/create',
    name: 'establishment-new',
    component: EstablishmentCreatePage,
  },
  {
    path: '/establishments/:slug',
    name: 'establishment.detail',
    redirect: { name: 'establishment.detail.overview' },
    component: EstablishmentDetailPage,
    meta: {
      pageTitle: 'Establishment',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
          active: false,
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
    children: [
      ...EstablishmentDetailRoutes,
    ],
  },
  {
    path: '/establishments/:slug/edit',
    name: 'establishment.edit',
    component: EstablishmentEditPage,
    meta: {
      pageTitle: 'Establishment',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
          active: false,
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
