<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <!-- <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          {{ appName }}
        </h2>
      </b-link> -->
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        xl="8"
        lg="6"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-80 d-lg-flex align-items-center justify-content-center mx-auto"
        >
          <b-img
            id="beSportyLogoLogin"
            fluid
            :src="imgUrl"
            class="px-5"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        xl="4"
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome to {{ appTitle }} ! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- form -->
          <login-form />

          <!--          <b-card-text class="text-center mt-2">-->
          <!--            <span>New on our platform? </span>-->
          <!--            <b-link :to="{ name:'register' }">-->
          <!--              <span>&nbsp;Register</span>-->
          <!--            </b-link>-->
          <!--            <div class="text-center mt-2">-->
          <!--              OR-->
          <!--            </div>-->
          <!--            <div class="text-center mt-2">-->
          <!--              <b-link :to="{ name:'request-organization' }">-->
          <!--                <span>&nbsp;Request an organization</span>-->
          <!--              </b-link>-->
          <!--              &lt;!&ndash;            </div>&ndash;&gt;-->
          <!--            </div>-->
          <!--          </b-card-text>-->

          <!--          &lt;!&ndash; divider &ndash;&gt;-->
          <!--          <div class="divider my-2">-->
          <!--            <div class="divider-text">-->
          <!--              or-->
          <!--            </div>-->
          <!--          </div>-->

          <!--          &lt;!&ndash; social buttons &ndash;&gt;-->
          <!--          <div class="auth-footer-btn d-flex justify-content-center">-->
          <!--            <b-button-->
          <!--              variant="facebook"-->
          <!--              :disabled="loading"-->
          <!--              @click="onLoginFacebook"-->
          <!--            >-->
          <!--              <b-spinner-->
          <!--                v-if="loading"-->
          <!--                small-->
          <!--              />-->
          <!--              <feather-icon icon="FacebookIcon" />-->
          <!--            </b-button>-->
          <!--          </div>-->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  // BButton,
  // BSpinner,
  BRow,
  BCol,
  // BLink,
  BCardText,
  BCardTitle,
  BImg,
  // BButton,
  VBTooltip,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { $themeConfig } from '@themeConfig'
import LoginForm from '@/modules/auth/views/login-page/components/LoginForm.vue'

import { AUTH_LOGIN_FACEBOOK } from '@/modules/auth/store/action-types'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { computed, ref } from '@vue/composition-api'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    // BButton,
    // BSpinner,
    BRow,
    BCol,
    // BLink,
    BCardText,
    BCardTitle,
    BImg,
    // BButton,
    // VuexyLogo,

    LoginForm,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    const appTitle = computed(() => process.env.VUE_APP_TITLE)
    const loading = ref(false)
    return {
      appName,
      appLogoImage,
      loading,
      appTitle,
    }
  },
  data() {
    return {
      sideImg: require('@/assets/images/besporty/beSporty-logo-icon-slogan_3840px_transparent.png'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    async onLoginFacebook() {
      this.loading = true
      this.$store
        .dispatch(AUTH_LOGIN_FACEBOOK)
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Welcome ${response.data.user.full_name
                || response.data.user.username}`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text:
                'You have successfully logged. Now you can start to manage!',
            },
          })
          this.$router.push({ name: 'organization-choose' })
          // this.$router.push({ name: 'dashboard' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Login Failed',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
          this.$refs.refFormObserver.setErrors(error.response.data.errors)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
#beSportyLogoLogin {
  max-width: 800px;
}
</style>
