<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <validation-observer
          #default="{ invalid, handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <b-card no-body>
              <b-card-header class="flex-column align-items-start">
                <b-card-title>Add New Establishment</b-card-title>
                <b-card-text class="text-muted mt-25">
                  Search after your location and submit
                </b-card-text>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          label="Search Place"
                          label-for="google-autocomplete"
                        >
                          <vue-google-autocomplete
                            id="google-autocomplete"
                            ref="googleAutocomplete"
                            classname="form-control"
                            placeholder="Please type your address"
                            types="establishment"
                            country="de"
                            @placechanged="getAddressData"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          label="Google Place-ID"
                          label-for="googlePlaceId"
                        >
                          <b-form-input
                            id="googlePlaceId"
                            v-model="googlePlaceId"
                            disabled
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          label="Name"
                          label-for="name"
                        >
                          <b-form-input
                            id="name"
                            v-model="name"
                            disabled
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          label="Phone"
                          label-for="phone"
                        >
                          <b-form-input
                            id="phone"
                            v-model="phone"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          label="Website"
                          label-for="website"
                        >
                          <b-form-input
                            id="website"
                            v-model="website"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          label="Address"
                          label-for="address"
                        >
                          <b-form-input
                            id="address"
                            v-model="address"
                            disabled
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          label="Latitude"
                          label-for="latitude"
                        >
                          <b-form-input
                            id="latitude"
                            v-model="latitude"
                            disabled
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          label="Longitude"
                          label-for="longitude"
                        >
                          <b-form-input
                            id="longitude"
                            v-model="longitude"
                            disabled
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          type="submit"
                          :disabled="invalid || loading"
                        >
                          <b-spinner
                            v-if="loading"
                            small
                          />
                          Add Establishment
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-card>
          <div class="mb-1">
            <GmapMap
              ref="refMap"
              :center="myCoordinates"
              :zoom="zoom"
              style="width:100%;  height: 400px;"
              @dragend="handleDrag"
            >
              <div v-if="savedLocations.length > 0">
                <GmapMarker
                  v-for="(l, index) in savedLocations"
                  :key="index"
                  :position="{lat: l.geoPoint.latitude, lng: l.geoPoint.longitude}"
                />
              </div>
            </GmapMap>
            <!--                      <vue-google-autocomplete-->
            <!--                          id="map"-->
            <!--                          classname="form-control"-->
            <!--                          placeholder="Start typing"-->
            <!--                          v-on:placechanged="getAddressData"-->
            <!--                      >-->
            <!--                      </vue-google-autocomplete>-->
          </div>
          <div>
            <h3>Your coordinates:</h3>
            <p>{{ myCoordinates.lat }} Latitude, {{ myCoordinates.lng }} Longitude</p>
          </div>
          <div>
            <h3>Map coordinates:</h3>
            <p>{{ mapCoordinates.lat }} Latitude, {{ mapCoordinates.lng }} Longitude</p>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow, BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody, BButton, BForm, BFormGroup, BFormInput, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'
import { required, integer } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { gmapApi } from 'vue2-google-maps'
import { STORE_ESTABLISHMENT } from '@/modules/establishment/store/action-types'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
// import axios from '@/libs/axios'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    VueGoogleAutocomplete,
    BSpinner,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      geocoder: null,
    }
  },
  computed: {
    google() {
      return gmapApi
    },
    mapCoordinates() {
      if (!this.map) {
        return {
          lat: 0,
          lng: 0,
        }
      }
      return {
        lat: this.map.getCenter()
          .lat()
          .toFixed(4),
        lng: this.map.getCenter()
          .lng()
          .toFixed(4),
      }
    },
  },
  created() {
    if (localStorage.center) {
      this.myCoordinates = JSON.parse(localStorage.center)
    } else {
      this.$getLocation({})
        .then(coordinates => {
          this.myCoordinates = coordinates
        })
    }
    if (localStorage.zoom) {
      this.zoom = JSON.parse(localStorage.zoom)
    }
  },
  mounted() {
    // eslint-disable-next-line no-return-assign
    this.$refs.refMap.$mapPromise.then(map => this.map = map)
    this.$refs.googleAutocomplete.focus()
    this.$gmapApiPromiseLazy().then(() => {
      if (this.google) {
        this.geocoder = new this.google.maps.Geocoder()
      }
    })
  },
  methods: {
    handleDrag() {
      const center = {
        lat: this.map.getCenter().lat(),
        lng: this.map.getCenter().lng(),
      }
      const zoom = this.map.getZoom()

      localStorage.center = JSON.stringify(center)
      localStorage.zoom = JSON.stringify(zoom)
    },
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     */
    getAddressData(addressData, placeResultData) {
      this.latitude = addressData.latitude
      this.longitude = addressData.longitude
      this.myCoordinates = {
        lat: addressData.latitude,
        lng: addressData.longitude,
      }
      const obj = {
        geoPoint: {
          latitude: addressData.latitude,
          longitude: addressData.longitude,
        },
      }
      this.savedLocations = []
      this.savedLocations.push(obj)

      this.googlePlaceId = placeResultData.place_id
      this.name = placeResultData.name
      this.address = placeResultData.formatted_address
      this.types = placeResultData.types

      // this.getStreetAddressFrom(addressData.latitude, addressData.longitude)
    },
    // async getStreetAddressFrom(lat, long) {
    //   try {
    //     const { data } = await axios.get(
    //       `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyDwL6bl_ynf7NXBmXav-GvI7Ff8XR9tCXw`,
    //     )
    //     if (data.error_message) {
    //     } else {
    //     }
    //   } catch (error) {
    //   }
    // },
    onSubmit() {
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (!success) return
          this.loading = true
          this.$store.dispatch(STORE_ESTABLISHMENT, {
            google_place_id: this.googlePlaceId,
            name: this.name,
            address: this.address,
            latitude: this.latitude,
            longitude: this.longitude,
            phone: this.phone,
            website: this.website,
            types: this.types,
          })
            .then(() => {
              this.$router.push({ name: 'establishments' })
            })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Create Failed',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
          // this.errors = error.response.data.errors;
          this.$refs.refFormObserver.setErrors(error.response.data.errors)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  setup() {
    const loading = ref(false)
    const refMap = ref(null)
    const map = ref(null)

    const geocoder = ref(null)

    const name = ref(null)
    const phone = ref(null)
    const website = ref(null)
    const types = ref(null)
    const googlePlaceId = ref(null)
    const address = ref(null)
    const latitude = ref(null)
    const longitude = ref(null)

    const savedLocations = ref([])
    const myCoordinates = ref({
      lat: 0,
      lng: 0,
    })
    const zoom = ref(null)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {
    })

    const geocode = () => {
      this.geocoder.geocode({ placeId: this.placeId }, () => {
      })
    }

    // const geoloate = () => {
    //   navigator.geolocation.getCurrentPosition(position => {
    //     this.coordinates = {
    //       lat: position.coords.latitude,
    //       lng: position.coords.longitude,
    //     }
    //   })
    // }
    //
    // onMounted(geoloate)

    // locate()

    return {
      loading,
      geocoder,

      refMap,
      map,
      geocode,
      name,
      phone,
      website,
      types,
      googlePlaceId,
      address,
      latitude,
      longitude,
      savedLocations,
      // Form

      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,

      required,
      integer,

      myCoordinates,
      zoom,
    }
  },
}
</script>

<style scoped>

</style>
