import StatisticsModule from '@/modules/statistics/StatisticsModule.vue'
import StatisticsPage from '@/modules/statistics/views/statistics-page/StatisticsPage.vue'

export default [
  {
    path: '/statistics',
    component: StatisticsModule,
    children: [
      {
        path: '/',
        name: 'statistics',
        component: StatisticsPage,
        meta: {
          pageTitle: 'Statistics',
          breadcrumb: [
            {
              text: 'Statistics',
              to: '/statistics',
              active: true,
            },
          ],
        },
      },
    ],
  },
]
