export const getDefaultState = () => ({
  status: '',
  token: localStorage.getItem('token') || '',
  hasLoadedOnce: false,
})

export default {
  token: getDefaultState().token,
  status: getDefaultState().status,
  hasLoadedOnce: getDefaultState().hasLoadedOnce,
}
