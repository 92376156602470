import WorkloudModule from '@/modules/workloud/WorkloudModule.vue'
import WorkloudsPage from '@/modules/workloud/views/worklouds-page/WorkloudsPage.vue'

export default [
  {
    path: '/worklouds',
    component: WorkloudModule,
    children: [
      {
        path: '/',
        name: 'worklouds',
        component: WorkloudsPage,
        meta: {
          pageTitle: 'Worklouds',
          breadcrumb: [
            {
              text: 'Worklouds',
              to: '/worklouds',
              active: true,
            },
          ],
        },
      },
    ],
  },
]
