<template>
  <!-- form -->
  <validation-observer
    ref="refFormObserver"
    #default="{invalid,handleSubmit}"
  >
    <b-form
      class="auth-forgot-password-form mt-2"
      @submit.prevent="handleSubmit(onSubmit)"
    >
      <!-- email -->
      <b-form-group
        label="Email"
        label-for="email"
      >
        <validation-provider
          #default="{ errors }"
          vid="email"
          name="Email"
          rules="required|email"
        >
          <b-form-input
            id="email"
            v-model="userEmail"
            :state="errors.length > 0 ? false:null"
            placeholder="Email"
            autocomplete="email"
          />
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </validation-provider>
      </b-form-group>

      <!-- submit button -->
      <b-button
        type="submit"
        variant="primary"
        block
        :disabled="invalid || loading"
        @click="onSubmit"
      >
        <b-spinner
          v-if="loading"
          small
        />
        Send reset link
      </b-button>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { email, required } from '@core/utils/validations/validations'
import { AUTH_SEND_RESET_LINK } from '@/modules/auth/store/action-types'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import { computed, ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  name: 'ForgotPasswordForm',
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    BForm,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    const loading = ref(false)
    const isDev = computed(() => process.env.VUE_APP_MODE === 'development')
    const userEmail = ref(isDev.value ? 'markus@besportyapp.com' : null)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {
    })

    return {
      appName,
      userEmail,
      isDev,
      appLogoImage,
      loading,

      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (!success) return
        this.loading = true
        // this.$router.push({ name: 'auth-reset-password-v1' })
        this.$store.dispatch(AUTH_SEND_RESET_LINK, { email: this.userEmail })
          .then(response => {
            if (response.data.data !== 'passwords.user' && response.data.data !== 'passwords.throttled') {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Email sent successfully!',
                  icon: 'MailIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.$router.push({ name: 'login' })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Reset Password Failed',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Reset Password Failed',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
            this.$refs.refFormObserver.setErrors(error.response.data.errors)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
  },
}
</script>

<style scoped>

</style>
