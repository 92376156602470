import employeesService from '@/modules/employee/services'
import {
  EMPLOYEE_UPDATE,
  FETCH_EMPLOYEES, FETCH_EMPLOYEES_FILTERS, INVITE_EMPLOYEE,
} from './action-types'

export default {
  [FETCH_EMPLOYEES]: (context, params) => new Promise((resolve, reject) => {
    employeesService.getEmployees(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [INVITE_EMPLOYEE]: (context, email) => new Promise((resolve, reject) => {
    employeesService.inviteEmployee(email)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [EMPLOYEE_UPDATE]: (context, data) => new Promise((resolve, reject) => {
    employeesService.updateEmployee(data)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_EMPLOYEES_FILTERS]: (context, data) => new Promise((resolve, reject) => {
    employeesService.getEmployeesFilters(data)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
