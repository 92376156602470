<template>
  <div>

    <establishment-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    />

    <b-card
      class="mb-0"
      no-body
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              variant="primary"
              :to="{ name: 'establishment-new'}"
            >
              Add Establishment
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />

              <b-button
                class="ml-1"
                variant="primary"
                @click="refetchData"
              >
                <span class="text-nowrap">Reload</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        ref="refEstablishmentListTable"
        class="position-relative"
        :items="fetchEstablishments"
        responsive
        :fields="tableColumns"
        primary-key="uuid"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: name -->
        <template #cell(name)="data">
          <b-link
            :to="{ name: 'establishment.detail', params: { slug: data.item.slug } }"
            class="font-weight-bold d-block text-nowrap"
          >
            <div>{{ data.item.name }}</div>
            <small>{{ data.item.place.name }}</small>
          </b-link>
        </template>

        <!-- Column: Status -->
        <template #cell(status_label)="data">
          <b-badge
            :variant="`light-${data.item.state}`"
            class="text-capitalize"
            pill
          >
            {{ data.item.status_label }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">

            <!-- Dropdown -->
            <b-dropdown
              :right="$store.state.appConfig.isRTL"
              no-caret
              variant="link"
            >

              <template #button-content>
                <feather-icon
                  class="align-middle text-body"
                  icon="MoreVerticalIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item :to="{ name: 'establishment.detail', params: { slug: data.item.place.slug } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'establishment.edit', params: { slug: data.item.place.slug } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item @click="deleteEstablishment(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

      </b-table>
      <div
        class="mx-2 mb-2"
      >
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.total }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalEstablishments"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BCard,
  BRow,
  BCol,
  BFormInput, BDropdown, BDropdownItem, BTable,
  BButton,
  BLink, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import EstablishmentFilters
  from '@/modules/establishment/views/establishments-page/components/establishment-filters/EstablishmentFilters.vue'
// eslint-disable-next-line import/no-cycle
import useEstablishments from '@/modules/establishment/views/establishments-page/useEstablishments'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { REMOVE_ESTABLISHMENT } from '@/modules/establishment/store/action-types'

export default {
  components: {
    EstablishmentFilters,
    BTable,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BBadge,
    BDropdown,
    BDropdownItem,
    BButton,
    BLink,
    BPagination,

    vSelect,
  },
  methods: {
    deleteEstablishment(establishment) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store
            .dispatch(REMOVE_ESTABLISHMENT, establishment.uuid)
            .then(() => {
              this.refetchData()
            })
        }
      })
    },
  },
  setup() {
    const {
      tableColumns,
      perPage,
      currentPage,
      totalEstablishments,
      statusFilter,
      statusOptions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEstablishmentListTable,

      fetchEstablishments,
      refetchData,
    } = useEstablishments()

    return {
      tableColumns,
      perPage,
      currentPage,
      totalEstablishments,
      statusFilter,
      statusOptions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEstablishmentListTable,

      fetchEstablishments,
      refetchData,
    }
  },
  // mounted() {
  //   if (!this.establishments) {
  //     this.loadEstablishments()
  //   }
  // },
  // methods: {
  //   async loadEstablishments() {
  //     this.showLoading = true
  //     const args = {}
  //     await this.$store.dispatch(FETCH_ESTABLISHMENTS, args)
  //       .then(response => {
  //         this.establishments = response.data.data
  //       })
  //       .finally(() => {
  //         this.showLoading = false
  //       })
  //   },
  // },
}
</script>

<style lang="scss" scoped>
</style>
