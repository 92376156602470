<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">

        <RegisterOrganizationForm />

        <b-card-text class="text-center mt-2">
          <span>Already have an account? </span>
          <b-link :to="{ name:'login' }">
            <span>Sign in instead</span>
          </b-link>
        </b-card-text>

        <!-- <div class="divider my-2">
          <div class="divider-text">
            or
          </div>
        </div> -->

        <!-- social buttons -->
        <!-- <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            variant="facebook"
            href="javascript:void(0)"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            variant="twitter"
            href="javascript:void(0)"
          >
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button
            variant="google"
            href="javascript:void(0)"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
        </div> -->
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import {
  BCard, BLink, BCardText,
  // BButton,
} from 'bootstrap-vue'
import RegisterOrganizationForm from '@/modules/auth/components/RegisterOrganizationForm.vue'

export default {
  components: {
    RegisterOrganizationForm,
    // BSV
    BCard,
    BLink,
    BCardText,
    // BButton,

  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
