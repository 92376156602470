<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <!-- form -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <!-- email -->
          <b-form-group
            label="Email"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                v-model="emailValue"
                :state="errors.length > 0 ? false:null"
                type="email"
                placeholder="Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- submit button -->
          <b-button
            variant="primary"
            block
            type="submit"
          >
            Invite
          </b-button>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm, BFormInput, BFormGroup, BButton, BCard,
  BCardHeader,
  BCardBody,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, email } from '@core/utils/validations/validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { INVITE_EMPLOYEE } from '@modules/employee/store/action-types'

export default {
  name: 'EmployeeEmailInviteForm',
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      emailValue: process.env.NODE_ENV === 'development' ? 'markus@besportyapp.com' : null,

      // validation rules
      required,
      email,
    }
  },
  methods: {
    onSubmit() {
      if (this.$refs.refFormObserver.validate()) {
        const data = {
          email: this.emailValue,
        }
        this.$store.dispatch(INVITE_EMPLOYEE, data)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Invitation email sent',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `${this.emailValue} invited successfully!`,
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Register Failed',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
            this.$refs.refFormObserver.setErrors(error.response.data.errors)
          })
      }
    },
  },
}
</script>

<style scoped>

</style>
