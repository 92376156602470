<template>
  <b-row class="p-1">

    <!-- Team Info: Left col -->
    <b-col
      cols="21"
      xl="6"
      class="d-flex justify-content-between flex-column"
    >
      <!-- Team Avatar & Action Buttons -->
      <div class="d-flex justify-content-start">
        <b-avatar
          :src="facilityData.image_big_thumbnail_url"
          :text="facilityData.name"
          size="104px"
          rounded
        />
        <div class="d-flex flex-column ml-1">
          <div class="my-1">
            <h4>
              {{ facilityData.name }}
            </h4>
          </div>
          <div class="d-flex flex-column flex-sm-row">
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              text="More"
              variant="outline-primary"
            >
              <b-dropdown-item
                @click="loadFacility"
              >
                <feather-icon
                  icon="RotateCwIcon"
                  size="18"
                  class="mr-1"
                />
                Reload
              </b-dropdown-item>
            </b-dropdown>
            <!-- <b-button
              v-if="establishment.is_following"
              class="btn-icon ml-1"
              variant="primary"
              @click="unfollow"
            >
              Unfollow
            </b-button> -->
            <b-dropdown
              variant="outline-secondary"
              no-caret
              toggle-class="btn-icon"
              class="btn-share ml-1"
              right
            >
              <template #button-content>
                <feather-icon icon="Share2Icon" />
              </template>
              <b-dropdown-item
                v-for="icon in [ 'FacebookIcon', 'TwitterIcon', 'YoutubeIcon', 'InstagramIcon', ]"
                :key="icon"
              >
                <feather-icon :icon="icon" />
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAvatar, BCol, BDropdown, BDropdownItem, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { FETCH_FACILITY_BY_SLUG } from '@/modules/facility/store/action-types'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  props: {
    facilityData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async loadFacility() {
      this.$store.dispatch(FETCH_FACILITY_BY_SLUG, this.$route.params.slug)
        .then(res => {
          this.facilityData = res.data.data
        })
    },
  },
}
</script>

<style scoped>

</style>
