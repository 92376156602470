<template>
  <b-card
    no-body
  >
    <establishment-detail-header-info-card :establishment-data="establishmentData" />
    <establishment-detail-header-tabs :establishment-data="establishmentData" />
  </b-card>
</template>
<script>
import {
  BCard,
} from 'bootstrap-vue'
import EstablishmentDetailHeaderInfoCard
  from '@/modules/establishment/views/establishment-detail-page/components/EstablishmentDetailHeaderInfoCard.vue'
import EstablishmentDetailHeaderTabs
  from '@/modules/establishment/views/establishment-detail-page/components/EstablishmentDetailHeaderTabs.vue'

export default {
  components: {
    EstablishmentDetailHeaderTabs,
    EstablishmentDetailHeaderInfoCard,
    BCard,
  },
  props: {
    establishmentData: {
      type: Object,
      required: true,
    },
  },
}
</script>
