<template>
  <div>
    <b-card
      class="mb-0"
      no-body
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              variant="primary"
              :to="{ name: 'news-new'}"
            >
              Add Record
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                class="ml-1"
                variant="primary"
                @click="refetchData"
              >
                <span class="text-nowrap">Reload</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        ref="refNewsListTable"
        :items="fetchNews"
        responsive
        :fields="tableColumns"
        primary-key="uuid"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">

            <!-- Dropdown -->
            <b-dropdown
              :right="$store.state.appConfig.isRTL"
              no-caret
              variant="link"
            >

              <template #button-content>
                <feather-icon
                  class="align-middle text-body"
                  icon="MoreVerticalIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item :to="{ name: 'news.detail', params: { slug: data.item.slug } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'news.edit', params: { slug: data.item.slug } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item @click="deleteNews(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.total }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalNews"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { FETCH_NEWS, REMOVE_NEWS } from '@/modules/news/store/action-types'
import {
  BCard,
  BRow,
  BCol,
  BFormInput, BDropdown, BDropdownItem, BTable,
  BButton,
  BPagination,
} from 'bootstrap-vue'
import { ref, watch, computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BButton,
    BPagination,

    vSelect,
  },
  setup() {
    // Use toast
    const toast = useToast()

    const refNewsListTable = ref(null)

    const tableColumns = [
      { key: 'title', sortable: true },
      { key: 'body', sortable: false },
      { key: 'newsable_type', sortable: false },
      { key: 'newsable_id', sortable: false },
      { key: 'actions' },
    ]

    const perPage = ref(10)
    const totalNews = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref(null)

    const dataMeta = computed(() => {
      const localItemsCount = refNewsListTable.value ? refNewsListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalNews.value,
      }
    })

    const refetchData = () => {
      refNewsListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, statusFilter], () => {
      refetchData()
    })

    const fetchNews = (ctx, callback) => {
      store
        .dispatch(FETCH_NEWS, {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          status: statusFilter.value,
        })
        .then(response => {
          const { data, meta } = response.data

          callback(data)
          totalNews.value = meta.total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching news list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const deleteNews = news => {
      store
        .dispatch(REMOVE_NEWS, news.uuid)
        .then(() => {
          refetchData()
        })
    }

    return {
      currentPage,
      tableColumns,
      perPage,
      statusFilter,
      fetchNews,
      deleteNews,
      totalNews,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refNewsListTable,
      refetchData,
      //   news,
    }
  },
  methods: {
    handlePageChange(value) {
      this.currentPage = value
      this.loadNews()
    },
  },
}
</script>

<style lang="scss" scoped>
.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
