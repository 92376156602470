<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            id="beSportyLogo"
            fluid
            :src="imgUrl"
            class="px-5"
          />
        </b-link>
        <b-card-title class="mb-1">
          Forgot Password? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Enter your email and we'll send you instructions to reset your password
        </b-card-text>

        <forgot-password-form />

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name:'login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>

      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BCard, BLink, BCardText, BCardTitle,
  BImg,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import ForgotPasswordForm from '@/modules/auth/components/ForgotPasswordForm.vue'

export default {
  components: {
    ForgotPasswordForm,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BImg,
  },
  data() {
    return {
      imgUrl: require('@/assets/images/besporty/beSporty-logo_1024px_original.png'),
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.auth-inner::before, .auth-inner::after{
  background-image: none !important;
}
#beSportyLogo {
  max-width: 200px;
}
</style>
