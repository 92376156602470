import TeamsPage from '@/modules/team/views/teams-page/TeamsPage.vue'
import TeamDetailPage from '@/modules/team/views/team-detail-page/TeamDetailPage.vue'
import TeamDetailRoutes from '@/modules/team/views/team-detail-page/router'

export default [
  {
    path: '/teams',
    name: 'teams',
    component: TeamsPage,
    meta: {
      pageTitle: 'Teams',
      breadcrumb: [
        {
          text: 'Teams',
          active: true,
        },
      ],
    },
  },
  {
    path: '/teams/:slug',
    name: 'team.detail',
    redirect: { name: 'team.detail.overview' },
    component: TeamDetailPage,
    meta: {
      pageTitle: 'Team',
      breadcrumb: [
        {
          text: 'Teams',
          to: '/teams',
          active: false,
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
    children: [
      ...TeamDetailRoutes,
    ],
  },
]
