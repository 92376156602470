// eslint-disable-next-line import/extensions
import OrganizationChoosePage from '@/modules/organization/views/organization-choose-page/OrganizationChoosePage'

export default [
  {
    path: '/choose-organization',
    name: 'organization-choose',
    component: OrganizationChoosePage,
    meta: {
      layout: 'full',
      // resource: 'Auth',
      // redirectIfLoggedIn: true,
    },
  },
]
