import { SET_TEAM_DETAIL_SLUG } from '@/modules/team/store/mutation-types'
import {
  FETCH_TEAMS,
  FETCH_TEAM,
  FETCH_TEAM_EVENTS,
  FETCH_TEAM_POLLS, FETCH_TEAM_MEMBERS, FETCH_TEAM_FOLLOWERS, FETCH_TEAM_HOSTINGS,
} from '@/modules/team/store/action-types'

import teamService from '../services'

export default {
  [FETCH_TEAMS]: (context, params) => new Promise((resolve, reject) => {
    teamService.getTeams(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_TEAM]: (context, slug) => new Promise((resolve, reject) => {
    context.commit(SET_TEAM_DETAIL_SLUG, slug)
    teamService.getTeam(slug)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_TEAM_MEMBERS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getTeamDetailSlug
    teamService.getMembers({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_TEAM_EVENTS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getTeamDetailSlug
    teamService.getEvents({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_TEAM_POLLS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getTeamDetailSlug
    teamService.getPolls({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_TEAM_FOLLOWERS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getTeamDetailSlug
    teamService.getFollowers({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_TEAM_HOSTINGS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getTeamDetailSlug
    teamService.getHostings({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
