import FacilityDetailOverview
  from '@/modules/facility/views/facility-detail-page/views/facility-detail-page-overview/FacilityDetailOverview.vue'
import FacilityDetailFacilities
  from '@/modules/facility/views/facility-detail-page/views/facility-detail-page-facilities/FacilityDetailFacilities.vue'
import FacilityDetailCourseDates
  from '@/modules/facility/views/facility-detail-page/views/facility-detail-page-course-dates/FacilityDetailCourseDates.vue'
import FacilityDetailEvents
  from '@/modules/facility/views/facility-detail-page/views/facility-detail-page-events/FacilityDetailEvents.vue'
import FacilityDetailSettings
  from '@/modules/facility/views/facility-detail-page/views/facility-detail-page-settings/FacilityDetailSettings.vue'

export default [
  {
    path: '',
    name: 'facility.detail.overview',
    component: FacilityDetailOverview,
    meta: {
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facilitys',
          to: '/facilitys',
          active: false,
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: 'facilities',
    name: 'facility.detail.facilities',
    component: FacilityDetailFacilities,
    meta: {
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facilitys',
          to: '/facilitys',
          active: false,
        },
        {
          text: 'Detail',
          active: true,
        },
        {
          text: 'Facilities',
          to: '/facilitys/facilities',
          active: false,
        },
      ],
    },
  },
  {
    path: 'courses',
    name: 'facility.detail.course-dates',
    component: FacilityDetailCourseDates,
    meta: {
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facilitys',
          to: '/facilitys',
          active: false,
        },
        {
          text: 'Detail',
          active: true,
        },
        {
          text: 'Courses',
          to: '/facilitys/courses',
          active: false,
        },
      ],
    },
  },
  {
    path: 'events',
    name: 'facility.detail.events',
    component: FacilityDetailEvents,
    meta: {
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facilitys',
          to: '/facilitys',
          active: false,
        },
        {
          text: 'Detail',
          active: true,
        },
        {
          text: 'Events',
          to: '/facilitys/events',
          active: false,
        },
      ],
    },
  },
  {
    path: 'settings',
    name: 'facility.detail.settings',
    component: FacilityDetailSettings,
    meta: {
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facilitys',
          to: '/facilitys',
          active: false,
        },
        {
          text: 'Detail',
          active: true,
        },
        {
          text: 'Settings',
          to: '/facilitys/settings',
          active: false,
        },
      ],
    },
  },
]
