import axios from 'axios'

export default {
  getTeams(params) {
    return axios({
      url: 'teams', params, data: null, method: 'GET',
    })
  },
  getTeam(slug) {
    return axios({
      url: `teams/slug/${slug}`, params: null, data: null, method: 'GET',
    })
  },
  getMembers({ slug, params }) {
    return axios({
      url: `teams/slug/${slug}/members`, params, data: null, method: 'GET',
    })
  },
  getEvents({ slug, params }) {
    return axios({
      url: `teams/slug/${slug}/events`, params, data: null, method: 'GET',
    })
  },
  getPolls({ slug, params }) {
    return axios({
      url: `teams/slug/${slug}/polls`, params, data: null, method: 'GET',
    })
  },
  getFollowers({ slug, params }) {
    return axios({
      url: `teams/slug/${slug}/followers`, params, data: null, method: 'GET',
    })
  },
  getHostings({ slug, params }) {
    return axios({
      url: `teams/slug/${slug}/hostings`, params, data: null, method: 'GET',
    })
  },
}
