import PublicPageModule from '@/modules/public-page/PublicPageModule.vue'
import PublicPagePage from '@/modules/public-page/views/public-page-page/PublicPagePage.vue'

export default [
  {
    path: '/public-page',
    component: PublicPageModule,
    children: [
      {
        path: '/',
        name: 'public-page',
        component: PublicPagePage,
        meta: {
          pageTitle: 'Public Page',
          breadcrumb: [
            {
              text: 'Public Page',
              to: '/public-page',
              active: true,
            },
          ],
        },
      },
    ],
  },
]
