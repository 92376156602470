<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <b-card no-body>
              <b-card-header class="flex-column align-items-start">
                <b-card-title>Edit Facility</b-card-title>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      label="Name"
                      label-for="name"
                    >
                      <b-form-input
                        id="name"
                        v-model="facility.name"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Details"
                      label-for="details"
                    >
                      <b-form-textarea
                        id="body"
                        v-model="facility.details"
                        placeholder="Details"
                        rows="6"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      type="submit"
                    >
                      Edit Facility
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>
      <b-col>
        <b-card no-body>
          <b-card-header class="flex-column align-items-start">
            <b-card-title>Edit Facility</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group
                  label="Name"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="facility.establishment.place.name"
                    disabled=""
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <b-form-group
                  label="Address"
                  label-for="address"
                >
                  <b-form-input
                    id="address"
                    v-model="facility.establishment.place.address"
                    disabled=""
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow, BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody, BButton, BForm, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'
import { FETCH_FACILITY, UPDATE_FACILITY } from '@/modules/facility/store/action-types'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { route } = useRouter()
    const facility = ref({})

    const loadFacility = async () => {
      store.dispatch(FETCH_FACILITY, route.value.params.slug)
        .then(res => {
          facility.value = res.data.data
        })
    }

    loadFacility()

    const onSubmit = () => {
      store.dispatch(UPDATE_FACILITY, {
        uuid: facility.value.uuid,
        data: { name: facility.value.name },
      })
    }

    return {
      facility,
      onSubmit,
    }
  },
}
</script>

<style scoped>

</style>
