import { computed, ref, watch } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  FETCH_FACILITY_EVENTS,
} from '@/modules/facility/store/action-types'

export default function useFacilityEvents() {
  const toast = useToast()

  const refEventListTable = ref(null)

  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'start_time', sortable: true },
    { key: 'sportCategory.name', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]

  const perPage = ref(10)
  const totalEvents = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('status')
  const isSortDirDesc = ref(true)

  const statusFilter = ref(null)
  const timeFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refEventListTable.value ? refEventListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEvents.value,
    }
  })

  const refetchData = () => {
    refEventListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, timeFilter], () => {
    refetchData()
  })

  const fetchEvents = async (ctx, callback) => {
    store
      .dispatch(FETCH_FACILITY_EVENTS, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        time: timeFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data

        callback(data)
        totalEvents.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching facility event list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    tableColumns,
    perPage,
    currentPage,
    totalEvents,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refEventListTable,

    fetchEvents,
    refetchData,

    statusFilter,
    timeFilter,
  }
}
